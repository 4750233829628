import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Typography,
  TextField,
  Button,
  Link,
  Box,
} from "@material-ui/core";

import { useAuthStyles } from "./authStyles";
import API from "../util/api";
import ApiError from "../Error/ApiError";
import { useFormik } from "formik";
import * as yup from "yup";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import { Email as EmailIcon } from "@material-ui/icons";
import { setConfirmDialogVisible } from "../Dialogs/confirmDialogSlice";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Syötä oikea sähköpostiosoite")
    .required("Sähköpostiosoite on pakollinen"),
});

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.mst-yhtiot.fi/">
        MST-Yhtiot Oy
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

//Request password reset
const PasswordResetRequestForm = () => {
  const classes = useAuthStyles();
  const [apiError, setApiError] = useState("");
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      handlePasswordResetRequest(values.email);
    },
  });
  const dispatch = useDispatch();

  const handlePasswordResetRequest = (email: string) => {
    API.passwordResetRequest(email).then((response: any) => {
      console.log("Okay, response", response);
      if (response.data.status === "failed") {
        setApiError(response.data.message);
      } else if (response.data.status === "success") {
        dispatch(setConfirmDialogVisible(true));
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <ConfirmDialog
        icon={<EmailIcon style={{ fontSize: 60 }} />}
        title="Salasanan resetointilinkki lähetetty"
        message=" Olemme lähettäneet sinulle linkin salasanan uusimiseen. Seuraa
    sähköpostin ohjeita vaihtaaksesi salasanasi."
        buttonUrl="/login"
        buttonText="Palaa kirjautumiseen"
      />
      <div className={classes.paper}>
        <Box>
          <div className={classes.loginLogo}>
            <img
              className={classes.logo}
              src={process.env.PUBLIC_URL + "/logo512x512.png"}
              alt="logo521"
            />
          </div>
        </Box>
        <Typography component="h3" variant="h5">
          Pyydä salasanan vaihtoa
        </Typography>
        <ApiError
          message={apiError}
          show={apiError.length > 0 ? true : false}
        />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Sähköposti"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Lähetä
          </Button>
        </form>
        <Link href="/login" variant="body2">
          Takaisin kirjautumiseen
        </Link>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
export default PasswordResetRequestForm;
