import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";

interface CameraState {
  count: number;
  images: CameraImageState[];
}

export interface CameraImageState {
  img: string;
  id: number;
}

const initialState: CameraState = {
  count: 0,
  images: [],
};

export const cameraSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    setImages: (state, action: PayloadAction<[]>) => {
      state.images = action.payload;
    },
    addImage: (state, action: PayloadAction<CameraImageState>) => {
      state.images = [...state.images, action.payload];
    },
    deleteImage: (state, action: PayloadAction<number>) => {
      state.images = state.images.filter(
        (image) => image.id !== action.payload
      );
    },
    updateImage: (state, action: PayloadAction<any>) => {
      const image = state.images.find(
        (image) => image.id === action.payload.id
      );
      if (image) image.img = action.payload.img;
    },
    increaseCount: (state) => {
      state.count = state.count + 1;
    },
    decreaseCount: (state) => {
      state.count = state.count - 1;
    },
  },
});

export const addImageAction =
  (image: any): AppThunk =>
  (dispatch) => {
    dispatch(addImage(image));
    dispatch(increaseCount());
  };

export const {
  addImage,
  deleteImage,
  setImages,
  increaseCount,
  decreaseCount,
  updateImage,
} = cameraSlice.actions;

export const selectImages = (state: RootState) => state.camera.images;
export const selectImageCount = (state: RootState) => state.camera.count;

export default cameraSlice.reducer;
