import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import { clearSelectedReceipt, ReceiptState } from "../../slices/receiptSlice";
import PDFfilePreview from "../../util/PDFfilePreview";
import API from "../../util/api";
import { format, parseISO } from "date-fns";
import { useReceiptStyles } from "./receiptStyles";
import { selectCompany } from "../../Customer/companySlice";
import PDFfilePreviewDialog from "../../util/PDFfilePreviewDialog";
import { useAppDispatch } from "../../app/store";
import { setCurrentPageNumber } from "../../slices/pageSlice";
import { createPDFfromResponse } from "../../util/pdfblob";

interface ReceiptDetailsDialogProps {
  receipt: ReceiptState;
  open: boolean;
  onClose: () => void;
}

const ReceiptDetailsDialog = (props: ReceiptDetailsDialogProps) => {
  const { receipt, open, onClose } = props;
  const selectedCorporate = useSelector(selectCompany);
  const [previewFilePath, setPreviewFilePath] = useState<any>("");
  const [showPDFfullscreenPreview, setShowPDFfullscreenPreview] =
    useState(false);
  const dispatch = useAppDispatch();
  const classes = useReceiptStyles();

  useEffect(() => {
    if (receipt.file !== "") {
      API.getGCSURL(receipt.file, receipt.user?.id.toString()).then(
        (response: any) => {
          const receiptPdfUrl = createPDFfromResponse(response.data);
          setPreviewFilePath(receiptPdfUrl);
        }
      );
    }
  }, [open]);

  const handleClose = () => {
    dispatch(clearSelectedReceipt());
    dispatch(setCurrentPageNumber(1));
    onClose();
  };

  return (
    <>
      <PDFfilePreviewDialog
        dialogOpen={showPDFfullscreenPreview}
        onClose={() => setShowPDFfullscreenPreview(false)}
        filePath={previewFilePath}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" flexGrow="1">
              <PDFfilePreview
                documentOnClick={() => setShowPDFfullscreenPreview(true)}
                filePath={previewFilePath}
                pageWidth={250}
              />
            </Box>
            <Box display="flex" flexDirection="column" flexGrow="1">
              <Box display="flex" flexDirection="row">
                <Typography variant="h5">{receipt.purchaseLocation}</Typography>
                <Typography variant="h5" style={{ marginLeft: "auto" }}>
                  {receipt.date !== ""
                    ? format(parseISO(receipt.date as string), "dd.MM.yyyy")
                    : null}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" pt={4}>
                <Typography variant="h6">Lisätiedot</Typography>
                <Typography variant="body1">
                  {receipt.additionalInfo}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ marginTop: "auto" }}
                >
                  <Box pt={2}>
                    <Typography variant="h6">Asiakas</Typography>
                    <Typography variant="body1">
                      {receipt.user ? receipt.user.name : null}
                    </Typography>
                  </Box>
                  <Box pt={2}>
                    <Typography variant="h6">Yritys</Typography>
                    <Typography variant="body1">
                      {selectedCorporate.businessName}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Sulje
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ReceiptDetailsDialog;
