// @ts-nocheck
import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { useHistory } from "react-router";
import imageCompression from "browser-image-compression";
// @ts-ignore

import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";
import getBase64 from "../../util/getBase64";
import {
  AppBar,
  Dialog,
  Box,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import AddPhotoIcon from "@material-ui/icons/AddAPhoto";
import CheckIcon from "@material-ui/icons/Check";
import RotateRightIcon from "@material-ui/icons/RotateRight";

import { useCameraStyles } from "./cameraStyles";
import {
  addImageAction,
  CameraImageState,
  deleteImage,
  selectImageCount,
  selectImages,
  setImages,
  updateImage,
} from "./cameraSlice";
import BottomNavButton from "../Navigation/BottomNavButton";
import AlertDialog from "../../Dialogs/AlertDialog";
import { setAlertDialogVisible } from "../../Dialogs/confirmDialogSlice";
import LoadingScreen from "../../LoadingScreen";
import { useParams } from "react-router-dom";
import {
  selectSelectedReceipt,
  setSelectedReceiptFile,
  updateReceiptFile,
} from "../../slices/receiptSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import FloatError from "../../Error/FloatError";

/**
 * Accepts list of images, and shows them as vertically scrollable view
 * - Option to remove taken camera pic
 * - Show selected image, can be set from props
 */
interface SelectedImage {
  img: string;
  id: number;
}
const CameraShotGallery = () => {
  let { editMode } = useParams<any>(); //EditMode = true
  const selectedReceipt = useSelector(selectSelectedReceipt || null);
  const cameraImages = useSelector(selectImages);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);
  const inputFile = useRef<any>(null);
  let history = useHistory();
  const images = useSelector(selectImages);
  const imageCount = useSelector(selectImageCount);
  const dispatch = useAppDispatch();
  let canvasRef = useRef<any>();

  const [selectedImage, setSelectedImage] = useState<SelectedImage>({
    img: "",
    id: 0,
  });
  const classes = useCameraStyles();

  //Set selected image to be just taken shot with camera by default
  useEffect(() => {
    if (images.length > 0) {
      const latestImageId = Math.max(...images.map((img) => img.id));
      const latestImage: any = images.find(
        (image) => image.id === latestImageId
      );
      setSelectedImage(latestImage);
    }
  }, [images]);

  const imageList = images.map((listImage: CameraImageState) => {
    return (
      <div
        onClick={() => setSelectedImage(listImage)}
        className={classes.pictureGalleryThumbnail}
      >
        <img
          src={listImage.img}
          alt="listimg"
          className={classes.pictureGalleryItemImg}
        />
      </div>
    );
  });

  //Handles deleting the currently selected image in the preview
  const handleImgDelete = () => {
    dispatch(deleteImage(selectedImage.id));
    setSelectedImage({
      img: "",
      id: 0,
    });
  };

  //Show confirm dialog
  const handleCloseClick = () => {
    dispatch(setAlertDialogVisible(true));
  };

  //Close gallery, clean up gallery images state
  const handleCloseGallery = () => {
    history.push("/customer");
    dispatch(setAlertDialogVisible(false));
    dispatch(setImages([]));
  };

  const handleCloseToEdit = () => {
    history.push(`/customer/receipt/details/edit/${selectedReceipt.id}`);
    dispatch(setAlertDialogVisible(false));
    dispatch(setImages([]));
  };

  const openDeviceFileDialog = () => {
    inputFile.current.click();
  };

  const handleCameraPicture = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    let imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    if (imageFile) {
      imageCompression(imageFile, options).then((compressedFile) => {
        setLoading(true);

        getBase64(compressedFile)
          .then((result: any) => {
            dispatch(
              addImageAction({
                img: result,
                id: imageCount,
              })
            );
            setLoading(false);
          })
          .catch((err: Error) => {
            console.log(err);
          });
        setLoading(false);
      });
    }
  };

  // When in edit mode, we want to send images to updateReceipt function
  // which accepts
  const handleUpdateFile = () => {
    const cameraImgs = cameraImages.map((image) => image.img);
    dispatch(
      updateReceiptFile({
        file: { file: cameraImgs },
        id: selectedReceipt.id as string,
      })
    )
      .then(unwrapResult)
      .then((originalPromise: any) => {
        if (originalPromise.status === "failed") {
          setApiError(originalPromise.message);
        } else if (originalPromise.status === "success") {
          console.log("originalpromise:", originalPromise);
          dispatch(setSelectedReceiptFile(originalPromise.data.file_url));
          dispatch(setImages([]));
          history.push(`/customer/receipt/details/edit/${selectedReceipt.id}`);
        }
      });
  };

  // We rotate currently selected image through canvas and update
  // it to the store.
  const rotateImage = () => {
    // Get canvas context
    let ctx = canvasRef.current.getContext("2d");

    // Create image of currently selected image (base64 string)
    let image = new Image();
    image.src = selectedImage.img;

    // Rotate image in image's onLoad function
    image.onload = () => {
      //Set canvas dimensions to image's
      const cw = image.naturalHeight;
      const ch = image.naturalWidth;
      canvasRef.current.width = cw;
      canvasRef.current.height = ch;
      //Rotate image in the canvas
      ctx.translate(cw / 2, ch / 2);
      ctx.rotate((90 * Math.PI) / 180);
      ctx.drawImage(image, -ch / 2, -cw / 2, ch, cw);
      // Get the new image from the canvas and convert back to base64
      let rotatedImg = canvasRef.current.toDataURL("image/png");
      // Update corresponding image in the store
      dispatch(updateImage({ img: rotatedImg, id: selectedImage.id }));
    };
  };

  return (
    <>
      <FloatError
        message={apiError}
        show={showApiError}
        handleClose={() => setShowApiError(false)}
      />
      <canvas ref={canvasRef} hidden />
      <Dialog
        fullScreen
        open={true}
        className={classes.galleryFullScreen}
        PaperProps={{
          style: {
            backgroundColor: "#fff",
            boxShadow: "none",
          },
        }}
      >
        <AlertDialog
          title="Peruuta muutokset"
          message="Haluatko peruutta tositteen luomisen?"
          handleCancel={() => dispatch(setAlertDialogVisible(false))}
          handleContinue={
            editMode ? () => handleCloseToEdit() : () => handleCloseGallery()
          }
        />
        <AppBar>
          <Toolbar>
            <IconButton
              onClick={() => handleCloseClick()}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Kuvat {images.length} / 5</Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.offset} />
        <Box
          flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          style={{ position: "relative" }}
        >
          <div className={classes.imgControlsOverlay}>
            <IconButton
              onClick={() => rotateImage()}
              className={classes.rotateButton}
              aria-label="rotate"
            >
              <RotateRightIcon style={{ color: "white" }} />
            </IconButton>
            <IconButton
              onClick={() => handleImgDelete()}
              className={classes.deleteButton}
              aria-label="delete"
              style={{ marginLeft: "auto" }}
            >
              <DeleteIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <div className={classes.selectedImgPreview}>
            {selectedImage.img.length > 0 ? (
              <img
                className={classes.selectedImg}
                src={selectedImage.img}
                alt="selectedimg"
              />
            ) : (
              <Box
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <Typography variant="h6">Ei kuvaa</Typography>
                <Typography variant="subtitle2">
                  Tositetta ei voi tallentaa ilman kuvaa
                </Typography>
              </Box>
            )}
          </div>
          <div className={classes.pictureGalleryContainer}>
            {imageList || null}
          </div>
        </Box>
        <BottomNavButton
          disabledLeft={images.length > 0 ? false : true}
          text="Hyväksy"
          actionIcon={<CheckIcon />}
          onClick={
            editMode
              ? () => handleUpdateFile()
              : () => history.push(`/customer/receipt/details`)
          }
          secondAction
          secondActionIcon={<AddPhotoIcon />}
          secondActionText="Lisää kuva"
          secondActionOnClick={() => openDeviceFileDialog()}
          disabledRight={images.length === 5 ? true : false}
        />
        <input
          ref={inputFile}
          capture="environment"
          accept="image/*"
          type="file"
          hidden
          multiple
          onChange={handleCameraPicture}
        />
        <LoadingScreen
          loading={loading}
          loadingText="Ladataan kuvaa... Odota hetki..."
        />
      </Dialog>
    </>
  );
};
export default CameraShotGallery;
