import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/store";
import {
  selectSelectedDate,
  setReceiptsFilter,
  setSelectedDate,
} from "../../../slices/receiptSlice";
import { IconButton, TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
} from "@material-ui/icons";
import { useMonthSelectStyles } from "./monthStyles";
import { subMonths, addMonths, getMonth, getYear, format } from "date-fns";

/**
 * Filters corporate specific receipt list based on a month selected
 * (also filtered by the corporate selected on the navbar )
 */
const MonthSelect = () => {
  const dispatch = useAppDispatch();
  const selectedDate = useSelector(selectSelectedDate);
  const [currentDate, setCurrentDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );
  const classes = useMonthSelectStyles();

  //Set receiptsFilter when changing the month
  useEffect(() => {
    //Parse month & year from the selectedDate and return filter object
    const formatMonthFilter = () => {
      const monthOfDate = getMonth(selectedDate).toString();
      const yearOfDate = getYear(selectedDate).toString();
      return { month: monthOfDate, year: yearOfDate };
    };
    dispatch(setReceiptsFilter(formatMonthFilter()));
  }, [dispatch, selectedDate]);

  //Custom textfield component for DatePicker, so we can style it as we want
  const CustomMonthTextField = (props: any) => {
    return (
      <TextField
        inputProps={{ className: classes.customMonthTextField }}
        {...props}
      />
    );
  };

  //Set receipts list selectedDate to the redux store
  const prevMonth = () => dispatch(setSelectedDate(subMonths(selectedDate, 1)));
  const nextMonth = () => {
    if (format(selectedDate, "yyyy-MM-dd") < currentDate)
      dispatch(setSelectedDate(addMonths(selectedDate, 1)));
  };

  return (
    <div className={classes.fullWidthBar}>
      <IconButton
        className={classes.arrowIconLeft}
        onClick={prevMonth}
        aria-label="monthLeft"
        color="inherit"
        size="small"
      >
        <ArrowLeftIcon fontSize="large" />
      </IconButton>
      <DatePicker
        InputProps={{
          disableUnderline: true,
        }}
        variant="dialog"
        value={selectedDate}
        views={["month", "year"]}
        onChange={() => console.log("change")}
        onYearChange={(date) => dispatch(setSelectedDate(date))}
        TextFieldComponent={(props) => <CustomMonthTextField {...props} />}
        okLabel="Hyväksy"
        cancelLabel="Peruuta"
        maxDate={new Date()}
      />
      <IconButton
        className={classes.arrowIconRight}
        onClick={nextMonth}
        aria-label="monthRight"
        color="inherit"
        size="small"
      >
        <ArrowRightIcon fontSize="large" />
      </IconButton>
    </div>
  );
};
export default MonthSelect;
