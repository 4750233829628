import { makeStyles } from "@material-ui/core/styles";

interface Props {
  fixed?: any;
  loading?: any;
  disabled?: any;
}
export const useNavStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  root: {
    //flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    //flexFlow: "flex-end",
    color: "#fff", //#07A650 = vaalea vihreä brandi väri
  },
  dropDownMenuContainer: {
    marginLeft: "auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    //width: "11rem",
    //overflow: "hidden",
  },
  dropDownMenu: {
    textAlign: "right",
    color: "#fff",
  },
  corpoName: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  bottomNav: {
    zIndex: 900,
    width: (props: Props) => (props.fixed ? "100%" : ""),
    position: (props: Props) => (props.fixed ? "fixed" : "static"),
    bottom: 0,
    marginTop: "auto",
    backgroundColor: (props: Props) =>
      props.loading || props.disabled ? "grey" : theme.palette.primary.main,
    color: (props: Props) => (props.loading ? "lightgrey" : "white"),
  },
  bottomNavAction: {
    color: "white",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  paper: {
    width: 280,
    display: "flex",
  },
  drawerHeader: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  drawerFooter: {
    color: "grey",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "auto",
  },
  drawerUserName: {
    padding: theme.spacing(1),
  },
  backDrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  logo: {
    flexGrow: 1,
    //width: "20px",
    maxWidth: "130px",
    maxHeight: "35px",
    paddingRight: "15px",
    marginRight: "15px",
    //maxWidth: "120px",
  },
}));
