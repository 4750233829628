/* eslint-disable */

/**
 * During development this camera approach was abandoned and we moved
 * to use input capture that opens mobile devices native camera - JH
 *
 * This component was left here just in case but can be probably deleted
 */
import React, { useState, useRef } from "react";
import adapter from "webrtc-adapter";
import Measure from "react-measure";
import { useDispatch, useSelector } from "react-redux";
import { useUserMedia } from "./useUserMedia";
import { useCardRatio } from "./useCardRatio";
import { useOffsets } from "./useOffsets";
import { Dialog, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import LensIcon from "@material-ui/icons/Lens";

import { useCameraStyles } from "./cameraStyles";
import { addImage, increaseCount, selectImageCount } from "./cameraSlice";
import { useHistory } from "react-router";

/**
 * NOTE ABOUT SUPPORT
 * - Chrome Mobile = Shows image out of focus, blurry
 * - Firefox Mobile, shows it fine, clear
 * - Safari Mobile, shows it fine, clear.
 *   * Has problems when using react-router. Needs refresh to see the stream again.
 *   * Users also have to manually set permission to persist in this case
 *   * Only way would be to use it as conditionally rendered component dialog !!
 *
 * Requested resolutio does not affect quality on chrome.
 *  - Tried to install webRTC adapter.js for better support, no help
 *
 */

const CAPTURE_OPTIONS = {
  audio: false,
  video: {
    facingMode: { exact: "environment" },
    //width: 1280,
    //height: 720,
    //aspectRatio: 1,
    focusMode: "single-shot",
  },
};

/**
 * TO-DO
 * 1. Add the state to redux, so we can return to gallery screen
 * 2. make gallery SEPARATE FROM CAMERA FOR EASIER CONTROL!
 * 2. Make ui better
 * 3. Remove option for images in list
 * 4. Show tak a new pic and accept button
 */
const Camera = () => {
  const dispatch = useDispatch();
  const imageCount = useSelector(selectImageCount);
  const classes = useCameraStyles({});

  let cameraRef = useRef<any>();
  let canvasRef = useRef<any>();
  let history = useHistory();

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  const [container, setContainer] = useState({ height: 0, width: 0 });
  // default card ratio 1.586, default 1080x1920 ratio is 0.5626
  const [aspectRatio, calculateRatio] = useCardRatio(0.75);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const offsets = useOffsets(
    cameraRef.current && cameraRef.current.videoWidth,
    cameraRef.current && cameraRef.current.videoHeight,
    container.width,
    container.height
  );

  if (mediaStream && cameraRef.current && !cameraRef.current.srcObject) {
    cameraRef.current.srcObject = mediaStream;
  }

  const handleCanPlay = () => {
    calculateRatio(cameraRef.current.videoHeight, cameraRef.current.videoWidth);
    cameraRef.current.play();
  };

  const handleResize = (contentRect: any) => {
    setContainer({
      height: Math.round(contentRect.bounds.width / aspectRatio),
      width: contentRect.bounds.width,
    });
  };

  const handleTakePicture = () => {
    console.log("Try to tak a pic!");

    let context = canvasRef.current.getContext("2d");
    context.drawImage(
      cameraRef.current, // source
      offsets.x, // sx
      offsets.y, // sy
      container.width, // sWidth
      container.height, // sHeight
      0, // dx
      0, // dy
      container.width, // dWidth
      container.height // dHeight
    );
    let newImage = canvasRef.current.toDataURL("image/png");
    dispatch(addImage({ img: newImage, id: imageCount }));
    dispatch(increaseCount());

    handleClear();
    history.push("/customer/camera/gallery");
  };

  const handleClear = () => {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    //onClear();
  };

  return (
    <Dialog
      fullScreen
      open={true}
      className={classes.fullScreen}
      PaperProps={{
        style: {
          backgroundColor: "#000",
          boxShadow: "none",
        },
      }}
    >
      <Box className={classes.cameraContentWrapper}>
        <Box style={{ padding: 3 }}>
          <CloseIcon
            onClick={() => history.goBack()}
            style={{ color: "white" }}
          />
          {/*   <div
            style={{
              color: "white",
              fontSize: "15px",
              position: "absolute",
              top: "35px",
              left: "35px",
              zIndex: 1001,
            }}
          >
            cVideoWidth: {cameraRef.current && cameraRef.current.videoWidth},
            cVideoHeight: {cameraRef.current && cameraRef.current.videoHeight},
            containerHeight: {container.height}, containerWidth:{" "}
            {container.width}
          </div> */}
        </Box>
        <Measure bounds onResize={handleResize}>
          {({ measureRef }: any) => (
            <div
              className={classes.cameraLens}
              ref={measureRef}
              style={{
                height: `${container.height}px`,
              }}
            >
              <video
                ref={cameraRef}
                onCanPlay={handleCanPlay}
                style={{
                  top: `-${offsets.y}px`,
                  left: `-${offsets.x}px`,
                  position: "absolute",
                }}
                autoPlay
                playsInline
                muted
                id="video"
              />
              <canvas
                ref={canvasRef}
                width={container.width}
                height={container.height}
                style={{ position: "absolute", top: 0, left: 0 }}
                hidden
              ></canvas>
            </div>
          )}
        </Measure>
        <Box className={classes.cameraControls}>
          <LensIcon style={{ fontSize: 75 }} onClick={handleTakePicture} />
        </Box>
      </Box>
    </Dialog>
  );
};
export default Camera;
