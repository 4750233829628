import { makeStyles } from "@material-ui/core/styles";
export const useDialogStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100vh",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));
