import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface NotificationState {
  open: boolean;
  message: string;
}

const initialState: NotificationState = {
  open: false,
  message: "",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationOpen: (state, action: PayloadAction<any>) => {
      state.open = action.payload.open;
      state.message = action.payload.message;
    },
  },
});

export const { setNotificationOpen } = notificationSlice.actions;

export const selectNotificationOpenStatus = (state: RootState) =>
  state.notification.open;
export const selectNotifcationMessage = (state: RootState) =>
  state.notification.message;

export default notificationSlice.reducer;
