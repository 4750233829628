import { makeStyles } from "@material-ui/core/styles";
export const useReceiptStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  searchRow: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(1),
  },
  dialogPaper: {
    minHeight: "500px",
  },
}));
