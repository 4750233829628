import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Container,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  Grid,
  Link,
  Box,
} from "@material-ui/core";

import { useAuthStyles } from "./authStyles";
import { login } from "./userSlice";
import { useAppDispatch } from "../app/store";
import ApiError from "../Error/ApiError";
import { useFormik } from "formik";
import * as yup from "yup";
import { GooglePlayStoreLink, AppleAppStoreLink } from "../AppStores/AppStores";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Syötä oikea sähköpostiosoite")
    .required("Sähköpostiosoite on pakollinen"),
  password: yup
    .string()
    .min(8, "Salasanan tulisi olla vähintään 8 merkkiä pitkä")
    .required("Salasana on pakollinen"),
});

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {" © "}
      <Link color="inherit" href="https://www.mst-yhtiot.fi/">
        MST-Yhtiot Oy
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

//Login screen
const Login = () => {
  const classes = useAuthStyles();
  const dispatch = useAppDispatch();
  const [apiError, setApiError] = useState("");
  const [showStoreLinks, setShowStoreLinks] = useState(true);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { setSubmitting }) => {
      handleLogin(values.email, values.password);
      setSubmitting(false);
    },
  });
  let history = useHistory();

  /**
   * Do not show play & ios store links if visiting through PWA
   */
  useEffect(() => {
    if (
      window.matchMedia("(display-mode: standalone)").matches ||
      document.cookie.includes("app-platform")
    ) {
      setShowStoreLinks(false);
    } else {
      setShowStoreLinks(true);
    }
  }, []);

  const handleLogin = (email: string, password: string) => {
    dispatch(login({ email, password }))
      .then(unwrapResult)
      .then((originalPromise: any) => {
        if (originalPromise.error) {
          setApiError(originalPromise.message);
        } else {
          history.push("/");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Box>
          <div className={classes.loginLogo}>
            <img
              className={classes.logo}
              src={process.env.PUBLIC_URL + "/logo512x512.png"}
              alt="logo512"
            />
          </div>
        </Box>
        <Typography component="h3" variant="h5">
          Kirjaudu sisään
        </Typography>
        <ApiError
          message={apiError}
          show={apiError.length > 0 ? true : false}
        />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Sähköposti"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Salasana"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Muista minut"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={formik.isSubmitting}
          >
            Kirjaudu sisään
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/password-request-reset-form" variant="body2">
                Unohtuiko salasana?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/request-register" variant="body2">
                {"Ei tunnuksia? Pyydä rekisteröintiä"}
              </Link>
            </Grid>
          </Grid>
          {showStoreLinks && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                marginTop: "1.25rem",
              }}
            >
              <GooglePlayStoreLink />
              <AppleAppStoreLink />
            </div>
          )}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
export default Login;
