import React from "react";
import {
  Backdrop,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useNavStyles } from "./navStyles";

/**
 * Basic absolute positioned bottom navigation button to be used any screen
 */
interface BottomNavButtonProps {
  disabled?: boolean;
  disabledLeft?: boolean;
  disabledRight?: boolean;
  onClick?: () => void;
  text: string;
  secondAction?: boolean;
  secondActionText?: string | React.ElementType;
  secondActionOnClick?: () => void;
  actionIcon?: any;
  secondActionIcon?: any;
  fixed?: boolean;
  loading?: boolean;
}

const BottomNavButton = (props: BottomNavButtonProps) => {
  const {
    disabledLeft,
    disabledRight,
    disabled,
    onClick,
    text,
    secondAction,
    secondActionText,
    secondActionOnClick,
    actionIcon,
    secondActionIcon,
    fixed,
    loading,
  } = props;

  const classes = useNavStyles({ fixed, loading, disabled });

  return (
    <>
      <BottomNavigation className={classes.bottomNav} showLabels>
        <BottomNavigationAction
          disabled={disabledLeft ? disabledLeft : disabled}
          className={classes.bottomNavAction}
          label={text}
          onClick={onClick}
          icon={actionIcon}
        />
        {secondAction && (
          <BottomNavigationAction
            disabled={disabledRight ? disabledRight : disabled}
            className={classes.bottomNavAction}
            label={secondActionText}
            onClick={secondActionOnClick}
            icon={secondActionIcon}
          />
        )}
      </BottomNavigation>
      <Backdrop open={loading as boolean} className={classes.backDrop}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress color="inherit" />
          <Box mt={3}>
            <Typography variant="subtitle2">
              Tallennetaan tositetta.. Odota hetki...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};
export default BottomNavButton;
