import React, { useEffect } from "react";
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography,
} from "@material-ui/core";
import ExitIcon from "@material-ui/icons/ExitToApp";
import { useAppStyles } from "../appStyles";
import { getAuthUser, logout, selectUser } from "../../Auth/userSlice";

const NavSideBar = () => {
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  const classes = useAppStyles();

  let history = useHistory();

  useEffect(() => {
    dispatch(getAuthUser());
  }, []);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.header}>
        <div className={classes.headerLogoContainer}>
          <img
            className={classes.headerLogo}
            src={process.env.PUBLIC_URL + "/logo629x200.png"}
            alt="mstosite-629-logo"
          />
        </div>
        <Typography variant="subtitle2">{user.name}</Typography>
      </div>
      <Divider />
      <List>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/staff")}
            primary="Tositelistaus"
          ></ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText
            onClick={() => history.push("/staff/printlog")}
            primary="Tulostusloki"
          ></ListItemText>
        </ListItem>
      </List>
      <div className={classes.drawerFooter}>
        <ListItem
          button
          onClick={() =>
            dispatch(logout()).then((response: any) => history.push("/login"))
          }
        >
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText>Kirjaudu ulos</ListItemText>
        </ListItem>
      </div>
    </Drawer>
  );
};
export default NavSideBar;
