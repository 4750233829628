import React, { ChangeEvent, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { useReceiptStyles } from "./receiptStyles";
import { addImageAction, selectImageCount } from "../Camera/cameraSlice";
import getBase64 from "../../util/getBase64";
import imageCompression from "browser-image-compression";
import LoadingScreen from "../../LoadingScreen";
import FloatError from "../../Error/FloatError";

//Container page for the receipt add options (upload & take a pic)
const ReceiptAddBottomNav = () => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);
  const inputFile = useRef<any>(null);
  const imageCount = useSelector(selectImageCount);
  const dispatch = useDispatch();
  let history = useHistory();
  let { url } = useRouteMatch();
  const classes = useReceiptStyles();
  const openDeviceFileDialog = () => inputFile.current.click();

  const handleCameraPicture = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    let imageFile = e.target.files[0];

    setLoading(true);

    /**
     * Check that it's actually image type before running it throuh compressing function
     */
    if (imageFile) {
      const fileExt = imageFile.name.split(".").pop();
      if (fileExt !== "png" && fileExt !== "jpg") {
        console.log("Image extension was not image:", fileExt);
        setApiError("Tiedoston tulee olla kuva");
        setShowApiError(true);
        return;
      }
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    if (imageFile) {
      imageCompression(imageFile, options).then((compressedFile) => {
        setLoading(true);
        getBase64(compressedFile)
          .then((result: any) => {
            dispatch(
              addImageAction({
                img: result,
                id: imageCount,
              })
            );
            setLoading(false);
            history.push("/customer/camera/gallery");
          })
          .catch((err: Error) => {
            console.log(err);
          });
        setLoading(false);
        history.push("/customer/camera/gallery");
      });
    }
  };

  return (
    <>
      <FloatError
        message={apiError}
        show={showApiError}
        handleClose={() => setShowApiError(false)}
      />
      <BottomNavigation className={classes.container} showLabels>
        <BottomNavigationAction
          className={classes.bottomNavButton}
          onClick={() => openDeviceFileDialog()}
          label="Ota kuva"
          icon={<AddAPhotoIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavButton}
          onClick={() => history.push(`${url}/receipt/file/upload`)}
          label="Lataa tiedosto"
          icon={<GetAppIcon />}
        />
      </BottomNavigation>
      <input
        ref={inputFile}
        capture="environment"
        accept="image/*"
        type="file"
        hidden
        multiple
        onChange={handleCameraPicture}
      />
      <LoadingScreen
        loading={loading}
        loadingText="Ladataan kuvaa... Odota hetki..."
      />
    </>
  );
};
export default ReceiptAddBottomNav;
