import { makeStyles } from "@material-ui/core/styles";
export const useAppStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
    background: "#fff",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headerLogoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  headerLogo: {
    width: "100%",
  },
  drawerFooter: {
    color: "grey",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "auto",
  },
}));
