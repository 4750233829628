import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams, useHistory } from "react-router-dom";
import { createPDFfromResponse } from "../../util/pdfblob";

import {
  Container,
  Box,
  TextField,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { KeyboardDatePicker } from "@material-ui/pickers";
import BottomNavButton from "../Navigation/BottomNavButton";
import { useReceiptStyles } from "./receiptStyles";
import { format, parseISO } from "date-fns";

import DeleteIcon from "@material-ui/icons/Delete";
import AddCameraIcon from "@material-ui/icons/AddAPhoto";
import AddPhotoIcon from "@material-ui/icons/AddPhotoAlternate";

import {
  CompanyState,
  selectCompany,
  selectUserCompanies,
} from "../companySlice";

import {
  createReceipt,
  deleteReceipt,
  getReceipt,
  selectSelectedReceipt,
  setSelectedReceipt,
  updateReceipt,
} from "../../slices/receiptSlice";

import { useFormik } from "formik";
import * as yup from "yup";
import PDFfilePreview from "../../util/PDFfilePreview";
import PDFfilePreviewDialog from "../../util/PDFfilePreviewDialog";
import API from "../../util/api";
import { useAppDispatch } from "../../app/store";
import {
  addImageAction,
  selectImageCount,
  selectImages,
  setImages,
} from "../Camera/cameraSlice";
import CameraImagesPreview from "./CameraImagesPreview";
import { setNavBarActionStatus } from "../Navigation/navSlice";
import AlertDialog from "../../Dialogs/AlertDialog";
import { setAlertDialogVisible } from "../../Dialogs/confirmDialogSlice";
import imageCompression from "browser-image-compression";
import getBase64 from "../../util/getBase64";
import FloatError from "../../Error/FloatError";
import { setNotificationOpen } from "../../Snackbar/notificationSlice";
import LoadingScreen from "../../LoadingScreen";

const validationSchema = yup.object({
  date: yup.date().nullable(),
  additionalInfo: yup
    .string()
    .required("Lisätiedot on pakollinen")
    .trim("Tyhjiä merkkejäe ei sallittu"),
  purchaseLocation: yup.string().required("Ostopaikka on pakollinen"),
  //company: yup.string().required("Yritysvalinta on pakollinen"),
});

/**
 * This form is used to create & edit receipts
 * URL param editMode:
 * edit param set = Enable editing receipt
 * no param set = Default, create new receipt
 */
const ReceiptForm = () => {
  let { editMode } = useParams<any>(); //EditMode = true
  let { receiptId } = useParams<any>(); //receipt's id to show in editMode
  let history = useHistory();
  const imageCount = useSelector(selectImageCount);
  const userCompanies = useSelector(selectUserCompanies);
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);
  const [alertDialogTxt, setAlertDialogTxt] = useState("");
  const [alertDialogTitle, setAlertDialogTitle] = useState("");
  const [showPDFfullscreenPreview, setShowPDFfullscreenPreview] =
    useState(false);
  const [previewFilePath, setPreviewFilePath] = useState<any>("");
  const [isCameraPhotosMode, setIsCameraPhotosMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const userCompany = useSelector(selectCompany);
  const selectedReceipt = useSelector(selectSelectedReceipt);
  const cameraImages = useSelector(selectImages);
  const dispatch = useAppDispatch();
  const classes = useReceiptStyles();
  const inputFile = useRef<any>(null);
  const [currentDate, setCurrentDate] = useState<any>("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: selectedReceipt.date || format(new Date(), "yyyy-MM-dd"),
      additionalInfo: selectedReceipt.additionalInfo || "",
      purchaseLocation: selectedReceipt.purchaseLocation,
      companyId: selectedReceipt.companyId || userCompany.id,
      file: selectedReceipt.file,
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { setSubmitting }) => {
      if (!editMode) {
        handleSaveClick();
      } else {
        handleEditClick();
      }

      //setSubmitting(false);
    },
  });

  useEffect(() => {
    let parsedDate = format(new Date(), "yyyy-MM-dd");
    let currentISODate = parseISO(parsedDate);
    setCurrentDate(currentISODate);
  }, []);

  //Fetch the receipt if selected receipt is empty
  useEffect(() => {
    if (editMode) {
      dispatch(getReceipt(receiptId));
    }
  }, []);

  useEffect(() => {
    //Disable all form fields if receipt has been processed by tilitoimisto if processed status has changed
    if (selectedReceipt.processed === 1) setFieldsDisabled(true);
  }, [selectedReceipt.processed]);

  useEffect(() => {
    //formik.setFieldValue("companyId", userCompany.id);
  }, [userCompany]);

  useEffect(() => {
    if (!editMode) {
      dispatch(setNavBarActionStatus("closeAndConfirm"));
      setAlertDialogTitle("Poistu tositteen luonnista");
      setAlertDialogTxt("Haluatko varmasti peruuttaa tositteen luomisen?");
    } else {
      dispatch(setNavBarActionStatus("back"));
    }
  }, []);

  useEffect(() => {
    //Set current date & company as default values to redux state.
    //NOTE: need this coz date needs to be set, if empty it breaks formik state - JH
    if (selectedReceipt.date === "" || selectedReceipt.companyId === "") {
      const newReceiptValues = {
        ...selectedReceipt,
        date: editMode ? selectedReceipt.date : formik.values.date,
      };
      dispatch(setSelectedReceipt(newReceiptValues));
    }
  }, []);

  useEffect(() => {
    //Get preview PDF stream when on the create mode of receipt
    //OR just image thumbnail preview if we store camera > images is not empty
    if (!editMode && cameraImages.length > 0) {
      setIsCameraPhotosMode(true);
    }

    if (!editMode && selectedReceipt.file !== "") {
      API.getConvertedPDF(selectedReceipt.file).then((response: any) => {
        const receiptPdfUrl = createPDFfromResponse(response.data);
        setPreviewFilePath(receiptPdfUrl);
      });
      //If edit mode, use actual url like google cloud files etc...
    } else if (editMode && selectedReceipt.file !== "") {
      API.getGCSURL(selectedReceipt.file).then((response: any) => {
        const receiptPdfUrl = createPDFfromResponse(response.data);
        setPreviewFilePath(receiptPdfUrl);
      });
    }
  }, [selectedReceipt.file]);

  const handleCameraPicture = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    let imageFile = e.target.files[0];

    setLoading(true);

    /**
     * Check that it's actually image type before running it throuh compressing function
     */
    if (imageFile) {
      const fileExt = imageFile.name.split(".").pop();
      if (fileExt !== "png" && fileExt !== "jpg") {
        setApiError("Tiedoston tulee olla kuva");
        setShowApiError(true);
        return;
      }
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    if (imageFile) {
      imageCompression(imageFile, options).then((compressedFile) => {
        setLoading(true);
        getBase64(compressedFile)
          .then((result: any) => {
            dispatch(
              addImageAction({
                img: result,
                id: imageCount,
              })
            );
            setLoading(false);

            history.push("/customer/camera/gallery/edit");
          })
          .catch((err: Error) => {
            console.log(err);
          });
        setLoading(false);
        history.push("/customer/camera/gallery/edit");
      });
    }
  };

  //Create Mode = Save new receipt
  //Edit Mode = Update receipt info
  //NOTE ADD FILE ARRAY SUPPORT FOR IMAGES IN REDUX IMAGES !!!!!
  const handleSaveClick = () => {
    const formattedDate = format(
      parseISO(formik.values.date as string),
      "yyyy-MM-dd"
    );
    let formattedValues = { ...formik.values, date: formattedDate };

    //if camera, add camera images to file data:
    if (isCameraPhotosMode) {
      const formattedCameraImages = cameraImages.map((image) => image.img);
      formattedValues = { ...formattedValues, file: formattedCameraImages };
    }

    if (!editMode) {
      dispatch(createReceipt(formattedValues))
        .then(unwrapResult)
        .then((originalPromise: any) => {
          if (originalPromise.status === "failed") {
            setApiError(originalPromise.message);
          } else if (originalPromise.status === "success") {
            history.push("/customer");
            dispatch(setImages([]));
            dispatch(
              setNotificationOpen({ open: true, message: "Tosite luotu" })
            );
          }
        });
    }
  };

  // Edit Mode = Edit receipt info
  const handleEditClick = () => {
    const formattedDate = format(
      parseISO(formik.values.date as string),
      "yyyy-MM-dd"
    );

    let formattedValues = {
      ...formik.values,
      date: formattedDate,
    };
    //dispatch(updateReceipt(formattedValues, selectedReceipt.id as string));

    dispatch(
      updateReceipt({
        receipt: formattedValues,
        id: selectedReceipt.id as string,
      })
    )
      .then(unwrapResult)
      .then((originalPromise: any) => {
        if (originalPromise.status === "failed") {
          setApiError(originalPromise.message);
        } else if (originalPromise.status === "success") {
          history.push("/customer");
          //dispatch(setImages([]));
          dispatch(
            setNotificationOpen({ open: true, message: "Tosite muokattu" })
          );
        }
      });
    //history.push("/customer")
  };

  //Edit Mode = Delete
  const handleDeleteClick = () => {
    setAlertDialogTitle("Tositteen poisto");
    setAlertDialogTxt("Haluatko varmasti poistaa tositteen?");
    dispatch(setAlertDialogVisible(true));
  };

  const handleDeleteReceipt = () => {
    dispatch(deleteReceipt(selectedReceipt.id as string))
      .then(unwrapResult)
      .then((originalPromise: any) => {
        if (originalPromise.status === "failed") {
          setApiError(originalPromise.message);
        } else if (originalPromise.status === "success") {
          history.push("/customer");
          //dispatch(setImages([]));
          dispatch(
            setNotificationOpen({ open: true, message: "Tosite poistettu" })
          );
        }
      });
    dispatch(setAlertDialogVisible(false));
  };

  const handleCloseReceiptForm = () => {
    history.push("/customer");
    /*   API.clearUserTmpFolder().then((response: any) => {
      console.log("User tmp cleared?");
    }); */
    dispatch(setAlertDialogVisible(false));
    dispatch(setImages([]));
    //dispatch clear tmp preview!
  };

  //Handle opening camera for changing the receipt pictures
  const handleOpenDeviceFileDialog = () => {
    //Call function which opens camera(input) and so on?
    inputFile.current.click();
  };

  //MenuItems for userCompanies list
  const userCompaniesList = userCompanies.map((company: CompanyState) => {
    return (
      <MenuItem value={company.id} key={company.id}>
        {company.businessName}
      </MenuItem>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <FloatError
        message={apiError}
        show={showApiError}
        handleClose={() => setShowApiError(false)}
      />
      <AlertDialog
        title={alertDialogTitle}
        message={alertDialogTxt}
        handleCancel={() => dispatch(setAlertDialogVisible(false))}
        handleContinue={() =>
          !editMode ? handleCloseReceiptForm() : handleDeleteReceipt()
        }
      />

      <PDFfilePreviewDialog
        dialogOpen={showPDFfullscreenPreview}
        onClose={() => setShowPDFfullscreenPreview(false)}
        filePath={previewFilePath}
      />

      <Container className={classes.receiptContainer}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            {editMode && selectedReceipt.processed === 0 ? (
              <Box
                display="flex"
                flexDirection="column"
                style={{ width: "35px", marginRight: "-35px" }}
              >
                <IconButton onClick={() => handleOpenDeviceFileDialog()}>
                  <AddCameraIcon color="primary" />
                </IconButton>
                <IconButton
                  onClick={() =>
                    history.push("/customer/receipt/file/upload/edit")
                  }
                >
                  <AddPhotoIcon color="primary" />
                </IconButton>
              </Box>
            ) : null}
            <Box display="flex" justifyContent="center" flexGrow="1">
              {!isCameraPhotosMode ? (
                <div className={classes.receiptImgContainer}>
                  <PDFfilePreview
                    documentOnClick={() => setShowPDFfullscreenPreview(true)}
                    filePath={previewFilePath}
                    pageWidth={80}
                  />
                  {!editMode && (
                    <Box alignItems="center" justifyContent="center">
                      <IconButton
                        onClick={() =>
                          history.push("/customer/receipt/file/upload")
                        }
                      >
                        <AttachFileIcon fontSize="large" />
                      </IconButton>
                    </Box>
                  )}
                </div>
              ) : (
                <CameraImagesPreview images={cameraImages} />
              )}
            </Box>
            {editMode && selectedReceipt.processed === 0 ? (
              <Box style={{ width: "35px", marginLeft: "-35px" }}>
                <IconButton
                  onClick={() => handleDeleteClick()}
                  aria-label="delete"
                >
                  <DeleteIcon style={{ color: "red" }} />
                </IconButton>
              </Box>
            ) : null}
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <KeyboardDatePicker
                disabled={fieldsDisabled}
                label="Päivämäärä"
                value={parseISO(formik.values.date)}
                onChange={(value) => {
                  formik.setFieldValue(
                    "date",
                    format(value as any, "yyyy-MM-dd")
                  );
                }}
                inputVariant="outlined"
                format="dd/MM/yyyy"
                fullWidth
                okLabel="Hyväksy"
                cancelLabel="Peruuta"
                maxDate={currentDate}
              />
            </Box>
            <Box>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">Yritys</InputLabel>
                <Select
                  disabled={!editMode ? true : fieldsDisabled}
                  name="companyId"
                  label="Yritys"
                  id="companyId"
                  value={formik.values.companyId}
                  onChange={formik.handleChange("companyId")}
                >
                  {userCompaniesList}
                </Select>
              </FormControl>
              <TextField
                disabled={fieldsDisabled}
                value={formik.values.purchaseLocation}
                onChange={formik.handleChange}
                error={
                  formik.touched.purchaseLocation &&
                  Boolean(formik.errors.purchaseLocation)
                }
                helperText={
                  formik.touched.purchaseLocation &&
                  formik.errors.purchaseLocation
                }
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="purchaseLocation"
                label="Ostopaikka"
                name="purchaseLocation"
                autoComplete="Ostopaikka"
                multiline
              />
              <TextField
                disabled={fieldsDisabled}
                value={formik.values.additionalInfo}
                onChange={formik.handleChange}
                error={
                  formik.touched.additionalInfo &&
                  Boolean(formik.errors.additionalInfo)
                }
                helperText={
                  formik.touched.additionalInfo && formik.errors.additionalInfo
                }
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="additionalInfo"
                label="Lisätiedot"
                name="additionalInfo"
                autoComplete="Lisätiedot"
                multiline
              />
            </Box>
          </form>
        </Box>
        <LoadingScreen
          loading={loading}
          loadingText="Ladataan kuvaa... Odota hetki..."
        />
      </Container>

      <BottomNavButton
        loading={formik.isSubmitting}
        fixed={true}
        text={
          selectedReceipt.processed === 0
            ? formik.isSubmitting
              ? "Tallennetaan..."
              : "Tallenna"
            : "Tosite käsitelty"
        }
        onClick={formik.submitForm}
        disabled={
          formik.isSubmitting || selectedReceipt.processed === 1 ? true : false
        }
      />
      <input
        ref={inputFile}
        capture="environment"
        accept="image/*"
        type="file"
        hidden
        multiple
        onChange={handleCameraPicture}
      />
    </div>
  );
};
export default ReceiptForm;
