import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useAppStyles } from "./appStyles";

//Component imports
import NavSideBar from "./Nav/NavSideBar";
import PrintLog from "./PrintLog/PrintLog";
import CorporateSearch from "./Receipt/CorporateSearch";
import ReceiptList from "./Receipt/ReceiptList";

//Staff app
// flow: yrityshaku > lista yrityksistä > valitaan yritys > listataan yrityksen tositteet
// - Haku yrityksen valinnan jälkeen kaikista yrityksen tositteista
// - Kk-kohtanen tositteiden selaus niinkuin sovellus puolellakin (asiakas soittelee ja kaivetaan tositetta)
const App = () => {
  let { path, url } = useRouteMatch();
  const classes = useAppStyles();

  return (
    <div className={classes.root}>
      <NavSideBar />
      <div className={classes.container}>
        <Switch>
          <Route exact path={path}>
            <CorporateSearch />
          </Route>
          <Route path={`${url}/company/:companyId?/receipts/:receiptIds?`}>
            <ReceiptList />
          </Route>
          <Route path={`${url}/printlog`}>
            <PrintLog />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
export { App };
