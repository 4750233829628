import { useState, useEffect } from "react";

interface RequestedMediaProps {
  audio?: boolean;
  video: any;
}
export function useUserMedia(requestedMedia: RequestedMediaProps) {
  const [mediaStream, setMediaStream] = useState<any>(null);

  useEffect(() => {
    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(
          requestedMedia
        );
        setMediaStream(stream);
      } catch (err) {
        // Removed for brevity
      }
    }

    if (!mediaStream) {
      enableStream();
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach((track: any) => {
          track.stop();
        });
      };
    }
  }, [mediaStream, requestedMedia]);

  return mediaStream;
}
