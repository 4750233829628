import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, TextField, Button, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useReceiptStyles } from "./receiptStyles";
import { getCompanies, selectCompanies, setSearchTerm } from "./companySlice";

import CorporateTableList from "./CorporateTableList";

//Search receipts with corporate name page
//Shows list of corporates
const CorporateSearch = () => {
  const dispatch = useDispatch();
  const companies = useSelector(selectCompanies);
  const classes = useReceiptStyles();

  useEffect(() => {
    dispatch(setSearchTerm(""));
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    console.log(companies);
  }, [setSearchTerm]);

  return (
    <Container maxWidth="lg">
      <div className={classes.searchContainer}>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          Hae tositteita yritysnimellä
        </Typography>
        <Typography variant="body2" style={{ textAlign: "center" }}>
          Hae käsittelemättömät valitsemalla hakuvaihtoehto ja syöttämällä
          hakuehto
        </Typography>
        <div className={classes.searchRow}>
          <TextField
            onChange={(event: any) =>
              dispatch(setSearchTerm(event.target.value))
            }
            style={{ marginLeft: 10 }}
            variant="outlined"
            label="Hakusana"
            fullWidth
          />
        </div>
        <div className={classes.searchRow}>
          <Button
            startIcon={<SearchIcon />}
            color="primary"
            variant="contained"
            fullWidth
          >
            Hae yrityksiä
          </Button>
        </div>
      </div>
      <CorporateTableList companies={companies} />
    </Container>
  );
};
export default CorporateSearch;
