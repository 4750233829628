import React from "react";
import { Paper, Box, IconButton, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ErrorOutlined as ErrorIcon,
  Close as CloseIcon,
} from "@material-ui/icons";

export const useApiErrorStyles = makeStyles((theme) => ({
  alert: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "rgb(253, 236, 234)",
    minWidth: "100%",
    minHeight: "35px",
    color: "#b30000",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorText: {
    margin: theme.spacing(1),
    lineHeight: "30px",
    fontWeight: "bold",
  },
  errorIcon: {
    margin: theme.spacing(1),
    marginTop: "10px",
  },
  snackbar: {
    [theme.breakpoints.down("xs")]: {
      bottom: 65,
    },
  },
  close: {
    marginLeft: "auto",
  },
}));

interface ApiErrorProps {
  message: string;
  show: boolean;
  handleClose: any;
}
/**
 * Position absolute error that floats above content

 * @param props 
 * @returns 
 */
const FloatError = (props: ApiErrorProps) => {
  const { message, show, handleClose } = props;
  const classes = useApiErrorStyles();

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={show}
        autoHideDuration={5000}
        onClose={handleClose}
        className={classes.snackbar}
      >
        <Paper className={classes.alert} elevation={1}>
          <ErrorIcon className={classes.errorIcon} />
          <Box className={classes.errorText}>{message}</Box>
          <IconButton
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Paper>
      </Snackbar>
    </div>
  );
};
export default FloatError;
