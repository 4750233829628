import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Typography, Box } from "@material-ui/core";
import PrintLogTable from "./PrintLogTable";
import { getPrintLogs, selectPrintLogs } from "../Receipt/printSlice";

const PrintLog = () => {
  const dispatch = useDispatch();
  const printlogs = useSelector(selectPrintLogs);

  useEffect(() => {
    dispatch(getPrintLogs());
  }, []);

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        style={{ marginBottom: "10px" }}
      >
        <Box display="flex" flexDirection="row" m={2}>
          <Box flexGrow="0">
            <Typography variant="h4">Tulostuslogi</Typography>
          </Box>
        </Box>
        <PrintLogTable printlogs={printlogs} />
      </Box>
    </Container>
  );
};
export default PrintLog;
