import { makeStyles } from "@material-ui/core/styles";

export const useMonthSelectStyles = makeStyles((theme) => ({
  fullWidthBar: {
    display: "flex",
    flexDirection: "row",
    //justifyContent: "space-between",
    height: "32px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  customMonthTextField: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    border: 0,
    width: "180px",
  },
  arrowIconLeft: {
    padding: 0,
    marginRight: "-10px",
    //margin: 0,
  },
  arrowIconRight: {
    padding: 0,
    marginLeft: "-10px",
    //margin: 0,
  },
}));
