import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  DataGrid,
  GridColDef,
  GridApi,
  GridCellValue,
  GridSortModel,
} from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import { format, parseISO } from "date-fns";
import { PrintLogState } from "../Receipt/printSlice";
import { GRID_FINNISH_LOCALE_TEXT } from "../../util/dataGridFiLocal";

interface PrintLogs {
  printlogs: PrintLogState[];
}

const PrintLogTable = (props: PrintLogs) => {
  const { printlogs } = props;
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "printedDate",
      sort: "desc",
    },
  ]);
  let history = useHistory();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "printedDate",
      headerName: "Tulostus pvm",
      width: 180,
      renderCell: (params) => (
        <div>
          {format(parseISO(params.value as string), "dd.MM.yyyy hh:mm:ss")}
        </div>
      ),
    },
    { field: "user", headerName: "Käyttäjä", width: 180 },
    { field: "companyName", headerName: "Yritys", width: 180 }, //new stuff need to add backend
    { field: "receiptsCount", headerName: "Tositteiden määrä", width: 180 },
    {
      field: "tositteet",
      flex: 1,
      align: "right",
      headerName: "Tositteet",
      renderCell: (params) => {
        const onClick = () => {
          const api: GridApi = params.api;
          const fields = api
            .getAllColumns()
            .map((c) => c.field)
            .filter((c) => c !== "__check__" && !!c);
          const thisRow: Record<string, GridCellValue> = {};

          fields.forEach((f) => {
            thisRow[f] = params.getValue(params.id, f);
          });
          const selectedPrintLog = printlogs.find(
            (element) => element.id === thisRow.id
          );
          const receiptIds = selectedPrintLog?.receiptsIds.map(
            (item) => item.id
          );
          history.push(`/staff/company/receipts?ids=${receiptIds}`);
        };

        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClick}
          >
            Näytä tositteet
          </Button>
        );
      },
    },
  ];

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        sortModel={sortModel}
        onSortModelChange={({ sortModel }: any) => setSortModel(sortModel)}
        localeText={GRID_FINNISH_LOCALE_TEXT}
        autoHeight
        rows={printlogs}
        columns={columns}
        pageSize={50}
        disableSelectionOnClick={true}
      />
    </div>
  );
};
export default PrintLogTable;
