import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

//This route checks if user is authenticated
interface PrivateRouteProps extends RouteProps {
  children: React.ReactNode;
  roles: Array<string>;
}

const checkRoles = (roles: Array<string>) => {
  const userRole = localStorage.getItem("user_role") || "";
  if (roles.includes(userRole)) return true;
  else return false;
};

const PrivateRoute = (props: PrivateRouteProps) => {
  const { children, roles, ...rest } = props;
  const isAuthenticated = localStorage.getItem("access_token") || "";
  const hasRole = checkRoles(roles);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated && hasRole ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};
export default PrivateRoute;
