import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

//State for PDF pages to get bigger file preview working
export interface PageState {
  pageNumber: number;
}

const initialState: PageState = {
  pageNumber: 1,
};

export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setCurrentPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
  },
});

export const { setCurrentPageNumber } = pageSlice.actions;

export const selectPageNumber = (state: RootState) => state.page.pageNumber;

export default pageSlice.reducer;
