import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PDFfilePreview from "../../util/PDFfilePreview";
import API from "../../util/api";
import { Container, Button, Box, Typography } from "@material-ui/core";
import ApiError from "../../Error/ApiError";

import { useReceiptStyles } from "./receiptStyles";
import BottomNavButton from "../Navigation/BottomNavButton";

import { useFormik } from "formik";
import * as yup from "yup";
import {
  selectSelectedReceipt,
  setSelectedReceiptFile,
  updateReceiptFile,
} from "../../slices/receiptSlice";
import { useSelector } from "react-redux";
import { createPDFfromResponse } from "../../util/pdfblob";
import { setNavBarActionStatus } from "../Navigation/navSlice";
import AlertDialog from "../../Dialogs/AlertDialog";
import { setAlertDialogVisible } from "../../Dialogs/confirmDialogSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../app/store";
import LoadingScreen from "../../LoadingScreen";

const validationSchema = yup.object({
  file: yup.string(),
});

//File size max limit (bytes)
const FILE_SIZE_LIMIT = 100000000;

/**
 * Upload one file view
 *
 */
const ReceiptFileUpload = () => {
  let { editMode } = useParams<any>(); //EditMode = true
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const selectedReceipt = useSelector(selectSelectedReceipt || null);
  const [file, setFile] = useState<any>(null);
  const [fileName, setFilename] = useState("Lataa tiedosto");
  const [previewFilePath, setPreviewFilePath] = useState<any>(
    !editMode ? selectedReceipt.file : "" || ""
  );
  let history = useHistory();
  const classes = useReceiptStyles();
  // const dispatch = useDispatch();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setNavBarActionStatus("closeAndConfirm"));
  }, []);

  // Get the file stream from current state always before componen render
  // if not set
  useEffect(() => {
    if (selectedReceipt.file !== "" && !editMode) {
      // if (!editMode) dispatch(clearSelectedReceipt());
      API.getConvertedPDF(selectedReceipt.file).then((response: any) => {
        const receiptPdfUrl = createPDFfromResponse(response.data);
        setPreviewFilePath(receiptPdfUrl);
      });
    } else {
      setPreviewFilePath("");
      setFile(null);
    }
  }, []);

  useEffect(() => {
    //Convert if file is present and is under 100 mb in size
    if (file !== null && file.size <= FILE_SIZE_LIMIT) {
      handleselectedFile();
    } else if (file !== null && file.size >= FILE_SIZE_LIMIT) {
      setApiError(
        `Tiedostokoko on liian suuri. Max. sallittu tiedostokoko on ${
          FILE_SIZE_LIMIT / 1000000
        }mb`
      );
    }
  }, [file]);

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      //console.log("No submit, change this to continue or something to");
    },
  });

  //Convert selected file to pdf and return pdf preview
  const handleselectedFile = () => {
    const formData = new FormData();
    formData.append("receiptFile", file);
    setLoading(true);
    API.convertToPDF(formData)
      .then((response: any) => {
        //If conversion is succesfull
        if (response.data.status === "success") {
          //Get the converted preview file as binarystream and
          //set it as an value for the preview component
          dispatch(setSelectedReceiptFile(response.data.data.fileName));
          API.getConvertedPDF(response.data.data.fileName)
            .then((response: any) => {
              const receiptPdfUrl = createPDFfromResponse(response.data);
              setPreviewFilePath(receiptPdfUrl);
            })
            .catch((_error) => {
              setApiError("Esikatselun luonti epäonnistui");
              setLoading(false);
            });
          setApiError("");
        } else if (response.data.status === "failed") {
          setApiError(response.data.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((_error) => {
        setApiError("Tiedoston lataus epäonnistui");
        setLoading(false);
      });
    setFile(null);
  };

  const handleCloseReceiptForm = () => {
    if (editMode) {
      history.push(`/customer/receipt/details/edit/${selectedReceipt.id}`);
    } else {
      history.push("/customer");
      //Needs to know exact file to remove if with same user
      /*       API.clearUserTmpFolder().then((response: any) => {
        // console.log("User tmp cleared?");
      }); */
    }
    dispatch(setAlertDialogVisible(false));
  };

  // When in edit mode, send request to update receipt's file
  const handleUpdateFile = () => {
    dispatch(
      updateReceiptFile({
        file: { file: selectedReceipt.file },
        id: selectedReceipt.id as string,
      })
    )
      .then(unwrapResult)
      .then((originalPromise: any) => {
        if (originalPromise.status === "failed") {
          setApiError(originalPromise.message);
        } else if (originalPromise.status === "success") {
          dispatch(setSelectedReceiptFile(originalPromise.data.file_url));
          history.push(`/customer/receipt/details/edit`);
        }
      });
  };

  return (
    <>
      <AlertDialog
        title="Peruuta muutokset"
        message="Haluatko peruutta tositteen luomisen?"
        handleCancel={() => dispatch(setAlertDialogVisible(false))}
        handleContinue={() => handleCloseReceiptForm()}
      />
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.fileUploadContainer}
        >
          <Box>
            {previewFilePath.length > 0 ? (
              <PDFfilePreview pageWidth={200} filePath={previewFilePath} />
            ) : null}
          </Box>
          <Box>
            <Typography variant="subtitle1">
              {fileName !== ""
                ? fileName
                : "Lataa tiedosto alla olevalla painikkeella."}
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Button
              variant="contained"
              component="label"
              className={classes.fileUploadButton}
            >
              {selectedReceipt.file !== "" ? "Vaihda..." : "Valitse..."}
              <input
                type="file"
                accept=".doc,.docx,.xls,.xlsx,.pdf,.jpg,.png,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet"
                hidden
                onChange={(event: any) => {
                  formik.setFieldValue("file", event.target.files[0]);
                  if (event.target.files[0]) {
                    setFile(event.target.files[0]);
                    setFilename(event.target.files[0].name);
                  }
                }}
              />
            </Button>
          </form>
          <Box
            fontStyle="italic"
            color="#a6a6a6"
            style={{ textAlign: "center" }}
          >
            <Typography variant="body2">
              Tuetut tiedostotyypit: <br />
              DOC, DOCX, XLS, XLSX, ODT, ODS, PDF, JPG, PNG
            </Typography>
            <Typography variant="body2">Max. tiedostokoko 100mb</Typography>
          </Box>
          <ApiError
            message={apiError}
            show={apiError.length > 0 ? true : false}
          />
        </Box>
      </Container>
      <BottomNavButton
        fixed={true}
        disabled={selectedReceipt.file !== "" ? false : true}
        text={editMode ? "Tallenna" : "Jatka"}
        onClick={
          editMode
            ? () => handleUpdateFile()
            : () => history.push(`/customer/receipt/details`)
        }
      />
      <LoadingScreen
        loading={loading}
        loadingText="Ladataan tiedostoa... Odota hetki..."
      />
    </>
  );
};
export default ReceiptFileUpload;
