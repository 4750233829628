import { makeStyles } from "@material-ui/core/styles";

export const useCameraStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  fullScreen: {
    backgroundColor: "#000000",
  },
  galleryFullScreen: {
    backgroundColor: "white",
  },
  cameraContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  //Overflowing container, actual camera feed is clipped from up and down
  //if ratio/resolution do notm atch
  cameraLens: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    minHeight: "90%",
  },
  cameraControls: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "120px",
    width: "100vw",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  //Picture gallery styles
  pictureGalleryContainer: {
    flexShrink: 0,
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
    background: "white",
  },
  //Picture galley image overflow and cropping to square
  //(to fit layout)
  pictureGalleryThumbnail: {
    display: "inline-block",
    position: "relative",
    width: 50,
    height: 50,
    overflow: "hidden",
    margin: 2,
  },
  pictureGalleryItemImg: {
    position: "absolute",
    left: "50%",
    top: "50%",
    width: "100%",
    height: "auto",
    WebkitTransform: "translate(-50%, -50%)",
    msTransform: "translate(-50%,-50%)",
    transform: "translate(-50%,-50%)",
  },
  selectedImgPreview: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    background: "white",
    alignItems: "flex-start", //to fix vertical image stretch bug in safari...
  },
  selectedImg: {
    width: "100%",
    height: "auto",
  },
  //The overlay controls to e.g delete selected image
  imgControlsOverlay: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    top: "2%",
    left: "2%",
    width: "96%",
  },
  rotateButton: {
    backgroundColor: "rgba(132, 132, 132, 0.5)",
  },
  deleteButton: {
    backgroundColor: "rgba(255, 23, 68, 0.5)",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}));
