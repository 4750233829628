import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import { ReceiptState } from "../../slices/receiptSlice";
import API from "../../util/api";

interface ReceiptsIds {
  id: string;
}
export interface PrintLogState {
  id: number;
  printedDate: string;
  user: string;
  receiptsCount: number;
  receiptsIds: ReceiptsIds[];
  companyName: string;
}
interface PrintState {
  receipts: ReceiptState[];
  printlogs: PrintLogState[];
}

const initialState: PrintState = {
  receipts: [],
  printlogs: [],
};

export const printSlice = createSlice({
  name: "print",
  initialState,
  reducers: {
    setPrintReceipts: (state, action: PayloadAction<ReceiptState[]>) => {
      state.receipts = action.payload;
    },
    setPrintLogs: (state, action: PayloadAction<PrintLogState[]>) => {
      const printlogs = action.payload.map((printlog: any) => {
        return {
          id: printlog.id,
          printedDate: printlog.printed_date,
          user: printlog.user.name,
          receiptsCount: printlog.receipts_count,
          receiptsIds: printlog.receipts,
          companyName: printlog.company_name,
        };
      });
      state.printlogs = printlogs;
    },
  },
});

export const getPrintLogs = (): AppThunk => (dispatch) => {
  return API.getPrintLogs().then((response: any) => {
    dispatch(setPrintLogs(response.data.data));
  });
};

export const { setPrintReceipts, setPrintLogs } = printSlice.actions;

export const selectPrintReceipts = (state: RootState) => state.print.receipts;
export const selectPrintLogs = (state: RootState) => state.print.printlogs;
//export const selectPrintLog = (state: RootState)

export default printSlice.reducer;
