import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectAlertVisibleStatus } from "./confirmDialogSlice";

//Smaller alert confirm dialogs, ok & cancle
interface AlertDialogProps {
  title: string;
  message: string;
  handleCancel: () => void;
  handleContinue: () => void;
}

const AlertDialog = (props: AlertDialogProps) => {
  const isVisible = useSelector(selectAlertVisibleStatus);
  const { title, message, handleCancel, handleContinue } = props;
  return (
    <Dialog
      open={isVisible}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Ei
        </Button>
        <Button onClick={handleContinue} color="primary" autoFocus>
          Kyllä
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AlertDialog;
