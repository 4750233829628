import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ReceiptList from "./Receipt/ReceiptList";
import NavBar from "./Navigation/NavBar";
import { getCompanies, getUserSelectedCompany } from "./companySlice";
import Camera from "./Camera/Camera";
import ReceiptForm from "./Receipt/ReceiptForm";
import ReceiptFileUpload from "./Receipt/ReceiptFileUpload";
import "./transitions.css";
import { getAuthUser } from "../Auth/userSlice";
import CameraShotGallery from "./Camera/CameraShotGallery";
import Privacy from "./Privacy/Privacy";
import { useAppStyles } from "./appStyles";
import Notification from "../Snackbar/Notification";
//Customer App
const App = () => {
  let location = useLocation();
  let { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const classes = useAppStyles();

  useEffect(() => {
    dispatch(getAuthUser());
    dispatch(getUserSelectedCompany());
    dispatch(getCompanies());
  }, []);

  const history = createBrowserHistory();
  history.listen((location, action) => {
    window.scrollTo(0, 0);
  });

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Notification />
      <NavBar />
      <TransitionGroup className={classes.transitionGroupRoot}>
        <CSSTransition key={location.key} classNames="page" timeout={300}>
          <Switch location={location}>
            <Route exact path={path}>
              <ReceiptList />
            </Route>
            <Route exact path={`${url}/camera`}>
              <Camera />
            </Route>
            <Route path={`${url}/camera/gallery/:editMode?`}>
              <CameraShotGallery />
            </Route>
            <Route path={`${url}/receipt/file/upload/:editMode?`}>
              <ReceiptFileUpload />
            </Route>
            <Route path={`${url}/receipt/details/:editMode?/:receiptId?`}>
              <ReceiptForm />
            </Route>
            <Route path={`${url}/privacy`}>
              <Privacy />
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};
export { App };
