import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface NavState {
  drawerOpen: boolean;
  navBarActionStatus: "menu" | "back" | "close" | "closeAndConfirm";
}

const initialState: NavState = {
  drawerOpen: false,
  navBarActionStatus: "menu",
};

export const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    toggleDrawerOpen: (state) => {
      state.drawerOpen = !state.drawerOpen;
    },
    setNavBarActionStatus: (
      state,
      action: PayloadAction<"menu" | "back" | "close" | "closeAndConfirm">
    ) => {
      state.navBarActionStatus = action.payload;
    },
  },
});

export const { toggleDrawerOpen, setNavBarActionStatus } = navSlice.actions;

export const selectDrawerOpenStatus = (state: RootState) =>
  state.nav.drawerOpen;

export const selectNavBarActionStatus = (state: RootState) =>
  state.nav.navBarActionStatus;

export default navSlice.reducer;
