import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Typography, Link, Box } from "@material-ui/core";
import { setNavBarActionStatus } from "../Navigation/navSlice";

const Privacy = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavBarActionStatus("back"));
  }, []);

  return (
    <Container>
      <Box mt={2} display="flex" flexDirection="column">
        <Typography variant="h6">Tietosuoja</Typography>
        <Box pb={2} pt={3}>
          <Link href="https://www.mst-yhtiot.fi/gdpr/GDPR-MST-tietosuojaseloste-sovellus.pdf">
            Tietosuojaseloste
          </Link>
        </Box>
        <Box pb={2}>
          <Link href="https://www.mst-yhtiot.fi/gdpr/GDPR-MST-periaatteet_ja_perusteet_tietosuoja_MST-sovellus.pdf">
            Tietosuojaperiaatteiden toteuttaminen ja käsittelyperusteiden
            arviointi
          </Link>
        </Box>
        <Box pb={1}>
          <Link href="https://www.mst-yhtiot.fi/gdpr/GDPR-MST-kasittelyseloste-sovellus.pdf">
            Seloste käsittelytoimista
          </Link>
        </Box>
      </Box>
    </Container>
  );
};
export default Privacy;
