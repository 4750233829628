import React, { useState } from "react";
import { ButtonGroup, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { selectPageNumber, setCurrentPageNumber } from "../slices/pageSlice";

/**
 * Renders a a provided PDF as interactive preview
 *
 */
interface PDFfilePreviewProps {
  filePath: any;
  pageWidth?: number;
  documentOnClick?: any;
}

const PDFfilePreview = (props: PDFfilePreviewProps) => {
  const { filePath, pageWidth, documentOnClick } = props;
  const [numPages, setNumPages] = useState(null);
  const pageNumber = useSelector(selectPageNumber);
  const dispatch = useDispatch();

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error: any) => {
    console.log("Document page Load Error", error.message);
  };

  const handleChangeNextPage = () => {
    const nextPage = pageNumber + 1;
    // @ts-ignore: Unreachable code error
    if (nextPage <= numPages) {
      dispatch(setCurrentPageNumber(nextPage));
    }
  };

  const handleChangePrevPage = () => {
    const nextPage = pageNumber - 1;
    // @ts-ignore: Unreachable code error
    if (nextPage > 0) {
      dispatch(setCurrentPageNumber(nextPage));
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div onClick={documentOnClick}>
          <Document
            file={filePath}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            error={<div>Esikatselun lataus epäonnistui</div>}
            onSourceError={(error: any) => console.log(error)}
          >
            <Page width={pageWidth || 250} pageNumber={pageNumber} />
          </Document>
        </div>

        <p>
          Sivu {pageNumber} / {numPages}
        </p>
        <ButtonGroup disableElevation size="small" color="primary">
          <Button onClick={handleChangePrevPage}>
            <ArrowBackIcon />
          </Button>
          <Button>
            {pageNumber} / {numPages}
          </Button>
          <Button onClick={handleChangeNextPage}>
            <ArrowForwardIcon />
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
};
export default PDFfilePreview;
