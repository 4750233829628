import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../app/store";
import API from "../util/api";

export interface CompanyState {
  id: string;
  crmCompanyId: string;
  businessName: string;
}

interface CompaniesState {
  selectedCompany: CompanyState;
  userCompanies: CompanyState[];
}

const initialState: CompaniesState = {
  selectedCompany: {
    id: "",
    crmCompanyId: "",
    businessName: "",
  },
  userCompanies: [],
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setSelectedCompany: (state, action: PayloadAction<any>) => {
      state.selectedCompany.id = action.payload.id;
      state.selectedCompany.crmCompanyId = action.payload.crm_company_id;
      state.selectedCompany.businessName = action.payload.business_name;
    },
    setUserCompanies: (state, action: PayloadAction<any>) => {
      const userCompanies = action.payload.map((company: any) => {
        return {
          id: company.id,
          crmCompanyId: company.crm_company_id,
          businessName: company.business_name,
        };
      });
      state.userCompanies = userCompanies;
    },
  },
});

export const { setSelectedCompany, setUserCompanies } = companySlice.actions;

export const getUserSelectedCompany = (): AppThunk => (dispatch) => {
  return API.getSelectedCompany().then((response: any) => {
    dispatch(setSelectedCompany(response.data.data));
  });
};

export const getCompanies = (): AppThunk => (dispatch) => {
  return API.getCompanies().then((response: any) => {
    dispatch(setUserCompanies(response.data.data));
  });
};

export const changeUserSelectedCompany = (id: number): AppThunk => (
  dispatch
) => {
  return API.setSelectedCompany(id).then((response: any) => {
    dispatch(setSelectedCompany(response.data.data));
  });
};

export const selectCompany = (state: RootState) =>
  state.company.selectedCompany;

export const selectUserCompanies = (state: RootState) =>
  state.company.userCompanies;

export default companySlice.reducer;
