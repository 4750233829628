import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "../Auth/userSlice";
import confirmDialogReducer from "../Dialogs/confirmDialogSlice";
import companyReducer from "../Customer/companySlice";
import staffCompanyReducer from "../Staff/Receipt/companySlice";
import receiptReducer from "../../src/slices/receiptSlice";
import navReducer from "../Customer/Navigation/navSlice";
import cameraReducer from "../Customer/Camera/cameraSlice";
import printReducer from "../Staff/Receipt/printSlice";
import pageReducer from "../../src/slices/pageSlice";
import notificationReducer from "../../src/Snackbar/notificationSlice";
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    user: userReducer,
    nav: navReducer,
    dialog: confirmDialogReducer,
    company: companyReducer,
    companies: staffCompanyReducer,
    receipt: receiptReducer,
    camera: cameraReducer,
    print: printReducer,
    page: pageReducer,
    notification: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
