import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../app/store";
import API from "../util/api";
import { getMonth, getYear } from "date-fns";
import { setSelectedCompany } from "../Customer/companySlice";

interface UserState {
  id: number;
  name: string;
}
export interface ReceiptState {
  id?: string;
  additionalInfo: string;
  purchaseLocation: string;
  companyId: string;
  date: any;
  file: any | any[];
  user?: UserState;
  processed?: number;
  updatedAt?: any;
}

export interface FileState {
  file: any | any[];
}

interface MonthFilter {
  month: string;
  year: string;
}

export interface ReceiptsState {
  selectedReceipt: ReceiptState;
  receipts: ReceiptState[];
  receiptsFilter: MonthFilter;
  searchTerm: string;
  selectedDate: any;
  statusFilter: string;
}

const initialState: ReceiptsState = {
  selectedReceipt: {
    id: "",
    additionalInfo: "",
    purchaseLocation: "",
    companyId: "",
    date: "",
    file: "",
    user: {
      id: 0,
      name: "",
    },
    processed: 0,
    updatedAt: "",
  },
  receipts: [],
  receiptsFilter: {
    month: "3",
    year: "2021",
  },
  searchTerm: "",
  statusFilter: "",
  selectedDate: new Date(), // "MonthSelect" components tracked current date
};

export const createReceipt = createAsyncThunk(
  "/create",
  async (receipt: ReceiptState) => {
    const response = await API.createReceipt(receipt);
    return response.data;
  }
);

export const updateReceipt = createAsyncThunk(
  "/update",
  async ({ receipt, id }: { receipt: ReceiptState; id: string }) => {
    const response = await API.updateReceipt(receipt, id);
    return response.data;
  }
);

//Update only receipt with file and receipt id provided
export const updateReceiptFile = createAsyncThunk(
  "/update/file",
  async ({ file, id }: { file: FileState; id: string }) => {
    const response = await API.updateReceiptFile(file, id);
    return response.data;
  }
);

export const deleteReceipt = createAsyncThunk("/delete", async (id: string) => {
  const response = await API.deleteReceipt(id);
  return response.data;
});

export const receiptSlice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    setSelectedReceipt: (state, action: PayloadAction<any>) => {
      const newValues = action.payload;
      const newObject = { ...state.selectedReceipt, ...newValues };
      state.selectedReceipt = newObject;
    },
    updateSelectedReceipt: (state, action: PayloadAction<any>) => {
      state.selectedReceipt = action.payload;
    },
    clearSelectedReceipt: (state, action: PayloadAction<void>) => {
      state.selectedReceipt = initialState.selectedReceipt;
    },
    setSelectedReceiptFile: (state, action: PayloadAction<string>) => {
      state.selectedReceipt.file = action.payload;
    },
    setReceipts: (state, action: PayloadAction<any>) => {
      const receipts = action.payload.map((receipt: any) => {
        return {
          id: receipt.id,
          additionalInfo: receipt.additional_info,
          purchaseLocation: receipt.purchase_location,
          companyId: receipt.company_id,
          date: receipt.date,
          file: receipt.file ? receipt.file.file_url : null,
          user: receipt.user,
          processed: receipt.processed,
          updatedAt: receipt.updated_at,
        };
      });
      state.receipts = receipts;
    },
    setReceiptProcessed: (state, action: PayloadAction<any>) => {
      state.receipts.map((receipt) =>
        receipt.id === action.payload ? (receipt.processed = 1) : receipt
      );
    },
    changeSelectedReceiptCompanyId: (state, action: PayloadAction<any>) => {
      state.selectedReceipt.companyId = action.payload;
    },
    setReceiptsFilter: (state, action: PayloadAction<MonthFilter>) => {
      state.receiptsFilter = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<any>) => {
      state.selectedDate = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload.toLowerCase();
    },
    setStatusFilter: (state, action: PayloadAction<string>) => {
      state.statusFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createReceipt.fulfilled, (state, { payload }: any) => {
      /*  state.receipts.map((receipt) =>
        receipt.id === payload.id ? { ...receipt, ...payload } : receipt
      ); */
      if (payload.data) {
        const formatReceipt = {
          id: payload.data.id,
          additionalInfo: payload.data.additional_info,
          purchaseLocation: payload.data.purchase_location,
          companyId: payload.data.company_id,
          date: payload.data.date,
          file: payload.data.file.file_url,
          updatedAt: payload.data.updated_at,
          processed: payload.data.processed,
        };
        state.receipts = [...state.receipts, formatReceipt];
      }
      return state;
    });

    builder.addCase(updateReceipt.fulfilled, (state, { payload }: any) => {
      const formatReceipt = {
        id: payload.data.id,
        additionalInfo: payload.data.additional_info,
        purchaseLocation: payload.data.purchase_location,
        companyId: payload.data.company_id,
        date: payload.data.date,
        processed: payload.data.processed,
        updatedAt: payload.data.updated_at,
        file: payload.data.file.file_url,
      };
      let newReceiptsState = state.receipts.map((receipt) =>
        receipt.id === payload.data.id
          ? { ...receipt, ...formatReceipt }
          : receipt
      );

      return { ...state, receipts: newReceiptsState };
    });

    builder.addCase(deleteReceipt.fulfilled, (state, { payload }: any) => {
      if (payload.data) {
        let newReceiptsState = state.receipts.filter(
          (receipt) => receipt.id !== payload.data.id
        );
        return { ...state, receipts: newReceiptsState };
      }
    });

    builder.addCase(updateReceiptFile.fulfilled, (state, { payload }: any) => {
      if (payload.data) {
        let newReceiptsState = state.receipts.filter(
          (receipt) => receipt.id !== payload.data.id
        );
        return { ...state, receipts: newReceiptsState };
      }
    });
  },
});

export const getReceipt =
  (id: string): AppThunk =>
  (dispatch) => {
    return API.getReceipt(id).then((response: any) => {
      const responseData = response.data.data;
      let responseFormatted = {
        id: responseData.id,
        additionalInfo: responseData.additional_info,
        purchaseLocation: responseData.purchase_location,
        companyId: responseData.company_id,
        date: responseData.date,
        file: responseData.file.file_url,
        processed: responseData.processed,
      };
      dispatch(updateSelectedReceipt(responseFormatted));
    });
  };

export const getReceipts = (): AppThunk => (dispatch) => {
  return API.getReceipts().then((response: any) => {
    dispatch(setReceipts(response.data.data));
  });
};

export const getReceiptsByCompanyId =
  (companyId: number): AppThunk =>
  (dispatch) => {
    return API.getReceiptsByCompanyId(companyId).then((response: any) => {
      dispatch(setReceipts(response.data.data.receipts));
      //use destructing to remove receipts data so we can set selectedCompany§
      const { receipts, ...rest } = response.data.data;
      dispatch(setSelectedCompany(rest));
    });
  };

export const getReceiptsByIds =
  (ids: string[]): AppThunk =>
  (dispatch) => {
    return API.getReceiptsByIds(ids).then((response: any) => {
      dispatch(setReceipts(response.data.data));
    });
  };

/* export const updateReceipt =
  (receipt: ReceiptState, id: string): AppThunk =>
  (dispatch) => {
    return API.updateReceipt(receipt, id).then((response: any) => {
      dispatch(setUpdateReceipt(response.data.data));
    });
  }; */

//Provide filename for rhe preview PDF and set the state for receipt files!
export const getPdfFilePreview =
  (fileName: string): AppThunk =>
  (dispatch) => {
    return API.getConvertedPDF(fileName).then((response: any) => {
      //dispatch(setSelectedReceiptFile(response.data.data.fileName));
    });
  };

export const setReceiptProcessedStatus =
  (receiptId: any): AppThunk =>
  (dispatch) => {
    return dispatch(setReceiptProcessed(receiptId));
  };

export const {
  setSelectedReceipt,
  updateSelectedReceipt,
  setReceipts,
  setSelectedReceiptFile,
  //setUpdateReceipt,
  clearSelectedReceipt,
  setReceiptsFilter,
  setSelectedDate,
  setSearchTerm,
  setStatusFilter,
  setReceiptProcessed,
  changeSelectedReceiptCompanyId,
} = receiptSlice.actions;

export const selectReceipts = (state: RootState) => {
  // Filter by month selection or
  // Filter by search term from the fields: additionalInfo & purchaseLocation
  let receipts = null;
  if (state.receipt.receiptsFilter.month && state.receipt.searchTerm === "") {
    receipts = state.receipt.receipts.filter((receipt: any) => {
      const month = getMonth(new Date(receipt.date)).toString();
      const year = getYear(new Date(receipt.date)).toString();
      return (
        receipt.companyId === state.company.selectedCompany.id &&
        month === state.receipt.receiptsFilter.month &&
        year === state.receipt.receiptsFilter.year
      );
    });
  } else if (state.receipt.searchTerm !== "") {
    receipts = state.receipt.receipts.filter((receipt: any) => {
      return (
        receipt.purchaseLocation
          .toLowerCase()
          .includes(state.receipt.searchTerm) ||
        receipt.additionalInfo.toLowerCase().includes(state.receipt.searchTerm)
      );
    });
  } else {
    receipts = state.receipt.receipts;
  }
  /*   return receipts.sort(
    (a: any, b: any) => <any>new Date(b.date) - <any>new Date(a.date)
  ); */
  return receipts.sort(
    (a, b) =>
      b.date.localeCompare(a.date) || b.updatedAt.localeCompare(a.updatedAt)
  );
};

const PROCESSED_STRINGS: any = {
  "0": "ei käsitelty",
  "1": "käsitelty",
};

export const selectReceiptsForCompany = (state: RootState) => {
  //ADD searchTerm FILTERING HERE !!!
  let data = null;

  if (
    state.receipt.searchTerm.length > 0 &&
    state.receipt.statusFilter.length > 0
  ) {
    const lowercasedFilter = state.receipt.searchTerm.toLowerCase();
    data = state.receipt.receipts.filter((receipt: any) => {
      return (
        PROCESSED_STRINGS[receipt.processed] === state.receipt.statusFilter &&
        (receipt.purchaseLocation.toLowerCase().includes(lowercasedFilter) ||
          receipt.additionalInfo.toLowerCase().includes(lowercasedFilter) ||
          receipt.user.name.toLowerCase().includes(lowercasedFilter))
      );
    });
    return data;
  } else if (
    state.receipt.searchTerm.length > 0 &&
    state.receipt.statusFilter === ""
  ) {
    const lowercasedFilter = state.receipt.searchTerm.toLowerCase();
    data = state.receipt.receipts.filter((receipt: any) => {
      return (
        receipt.purchaseLocation.toLowerCase().includes(lowercasedFilter) ||
        receipt.additionalInfo.toLowerCase().includes(lowercasedFilter) ||
        receipt.user.name.toLowerCase().includes(lowercasedFilter)
      );
    });
    return data;
  }

  if (state.receipt.statusFilter.length > 0) {
    const lowercasedFilter = state.receipt.statusFilter.toLowerCase();
    let filter: number = 0;
    if (lowercasedFilter === "käsitelty") filter = 1;
    else if (lowercasedFilter === "ei käsitelty") filter = 0;

    data = state.receipt.receipts.filter((receipt: any) => {
      return receipt.processed === filter;
    });
    return data;
  }
  return state.receipt.receipts;
};

//Sets the date to used by recept list month filter
export const selectSelectedDate = (state: RootState) => {
  return state.receipt.selectedDate;
};

//Return if filter are set or not
export const selectReceiptsFilterSetStatus = (state: RootState) => {
  return state.receipt.receiptsFilter.month && state.receipt.receiptsFilter.year
    ? true
    : false;
};

export const selectSelectedReceipt = (state: RootState) =>
  state.receipt.selectedReceipt;

export const selectStatusFilter = (state: RootState) =>
  state.receipt.statusFilter;

export const selectSearchTerm = (state: RootState) => state.receipt.searchTerm;

export default receiptSlice.reducer;
