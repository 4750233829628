import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import API from "../../util/api";

export interface CompanyState {
  id: string;
  crm_company_id: string;
  business_number: string;
  business_name: string;
}

interface CompaniesState {
  companies: CompanyState[];
  searchTerm: string;
}

const initialState: CompaniesState = {
  companies: [],
  searchTerm: "",
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompanies: (state, action: PayloadAction<CompanyState[]>) => {
      state.companies = action.payload;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
  },
});

export const { setCompanies, setSearchTerm } = companiesSlice.actions;

export const getCompanies = (): AppThunk => (dispatch) => {
  return API.getCompanies().then((response: any) => {
    dispatch(setCompanies(response.data.data));
  });
};

export const selectCompanies = (state: RootState) => {
  const lowercasedFilter = state.companies.searchTerm.toLowerCase();
  const filtered = state.companies.companies.filter((company: any) => {
    return company.business_name.toLowerCase().includes(lowercasedFilter);
    /*     return Object.keys(company).some(
      (key) =>
        typeof company[key] === "string" &&
        company[key].toLowerCase().includes(lowercasedFilter)
    ); */
  });
  return filtered;
};

export default companiesSlice.reducer;
