import React from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from "@material-ui/core";
import { CompanyState } from "./companySlice";

interface CorporateTableProps {
  companies: CompanyState[];
}
const CorporateTableList = (props: CorporateTableProps) => {
  const { companies } = props;

  const companyList = companies.map((item: CompanyState) => {
    return (
      <TableRow
        key={item.id}
        component={RouterLink}
        to={`/staff/company/${item.id}/receipts`}
      >
        <TableCell component="th" scope="row">
          {item.id}
        </TableCell>
        <TableCell component="th" scope="row">
          {item.crm_company_id}
        </TableCell>
        <TableCell component="th" scope="row">
          {item.business_number}
        </TableCell>
        <TableCell component="th" scope="row">
          {item.business_name}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Crm ID</TableCell>
            <TableCell>Y-tunnus</TableCell>
            <TableCell>Nimi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{companyList}</TableBody>
      </Table>
    </TableContainer>
  );
};
export default CorporateTableList;
