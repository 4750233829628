import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ReceiptState, FileState } from "../slices/receiptSlice";

//Add later to env
const API_URL = process.env.REACT_APP_API_URL;

interface BusinessInfoFormRequest {
  email: string;
  businessName: string;
  businessId: string;
  personName: string;
  phoneNumber: string;
}

interface PasswordResetRequest {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}

//Add Authorization Bearer header by default to requests?
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem("access_token") || "";
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

let authTokenRequest: any;

// This function makes a call to get the auth token
// or it returns the same promise as an in-progress call to get the auth token
const refreshToken = () => {
  return axios.get(`${API_URL}/refresh`);
};

const getAuthToken = () => {
  if (!authTokenRequest) {
    authTokenRequest = refreshToken();
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }

  return authTokenRequest;
};

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

//Refresh token response if expired token
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    const orgReq = error.config;

    //Check if its BLOB type as it needs to be converted back to json
    //to be able to access error message
    if (
      (await error.request.responseType) === "blob" &&
      error.response.status === 401
    ) {
      orgReq._retry = true;

      const response = await getAuthToken();

      localStorage.setItem("access_token", response.data.access_token);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + response.data.access_token;
      orgReq.headers["Authorization"] = "Bearer " + response.data.access_token;
      return axios.request(orgReq);
    } else if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.error === "token-expired" &&
      !orgReq._retry
    ) {
      orgReq._retry = true;

      console.log("Error status was 401 and token-expired");

      //const response = await axios.get(`${API_URL}/refresh`);
      const response = await getAuthToken();

      localStorage.setItem("access_token", response.data.access_token);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + response.data.access_token;
      orgReq.headers["Authorization"] = "Bearer " + response.data.access_token;

      //Okay, execute the original request with a new token bearer header!
      return axios.request(orgReq);
    } else if (
      error.response.status === 401 &&
      error.response.data.error === "token-invalid"
    ) {
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  /**
   * Authentication
   */
  requestRegister: (email: string) => {
    return axios.post(`${API_URL}/request-register`, { email });
  },
  login: (email: string, password: string) => {
    return axios.post(`${API_URL}/login`, { email, password });
  },
  logout: () => {
    return axios.get(`${API_URL}/logout`);
  },
  passwordResetRequest: (email: string) => {
    return axios.post(`${API_URL}/password/reset-request`, { email });
  },
  passwordReset: (formData: PasswordResetRequest) => {
    return axios.post(`${API_URL}/password/reset`, formData);
  },
  sendBusinessInfoForm: (formData: BusinessInfoFormRequest) => {
    return axios.post(`${API_URL}/send-business-info-form`, formData);
  },
  getAuthUser: () => {
    return axios.get(`${API_URL}/me`);
  },
  checkRegisterRequestToken: (token: string) => {
    return axios.get(`${API_URL}/request-register/checktoken/${token}`);
  },
  /**
   * Company
   */
  getSelectedCompany: () => {
    return axios.get(`${API_URL}/companies/user/selected`);
  },
  setSelectedCompany: (id: number) => {
    return axios.put(`${API_URL}/companies/user/selected/${id}`);
  },
  getCompanies: () => {
    return axios.get(`${API_URL}/companies`);
  },
  /**
   * Receipt
   */
  getReceipt: (id: string) => {
    return axios.get(`${API_URL}/receipt/${id}`);
  },
  getReceipts: () => {
    return axios.get(`${API_URL}/receipt`);
  },
  getReceiptsByIds: (ids: string[]) => {
    return axios.post(`${API_URL}/receipt/filter/ids`, ids);
  },
  getReceiptsByCompanyId: (companyId: number) => {
    return axios.get(`${API_URL}/receipt/company/${companyId}`);
  },
  createReceipt: (receipt: ReceiptState) => {
    return axios.post(`${API_URL}/receipt`, receipt);
  },
  updateReceipt: (receipt: ReceiptState, id: string) => {
    return axios.put(`${API_URL}/receipt/${id}`, receipt);
  },
  deleteReceipt: (id: string) => {
    return axios.delete(`${API_URL}/receipt/${id}`);
  },
  getPrintablePDF: (receipts: ReceiptState[]) => {
    return axios.post(`${API_URL}/receipt/print`, receipts);
  },
  processPrintables: (receiptIds: string[]) => {
    return axios.post(`${API_URL}/receipt/process`, receiptIds);
  },
  getPrintLogs: () => {
    return axios.get(`${API_URL}/printlogs`);
  },
  //Update only file for receipt
  updateReceiptFile: (file: FileState, id: string) => {
    return axios.put(`${API_URL}/receipt/file/update/${id}`, file);
  },
  /**
   * File upload & converter
   */
  convertToPDF: (formData: any) => {
    return axios.post(`${API_URL}/file/convert-to-pdf`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getConvertedPDF: (fileName: any) => {
    return axios.get(`${API_URL}/file/preview/tmp/${fileName}`, {
      responseType: "blob",
    });
  },

  //FOR TESTING BEFORE GOOGLE CLOUD FILES etc.
  getGCSURL: (fileUrl: any, userId = "") => {
    const user = userId.length > 0 ? `/${userId}` : "";
    return axios.get(`${API_URL}/file/preview/${fileUrl}${user}`, {
      responseType: "blob",
    });
  },

  //Clear user's tmp folder
  clearUserTmpFolder: () => {
    return axios.get(`${API_URL}/file/clear-tmp-preview`);
  },
};
