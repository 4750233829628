import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../app/store";
import { Warning as WarningIcon } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import API from "../util/api";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
} from "@material-ui/core";
import { useAuthStyles } from "./authStyles";
import { register } from "./userSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import ApiError from "../Error/ApiError";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import { setConfirmDialogVisible } from "../Dialogs/confirmDialogSlice";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Salasanan on oltava vähintään 8 merkkiä pitkä")
    .required("Salasana on pakollinen"),
  passwordAgain: yup
    .string()
    .oneOf([yup.ref("password"), null], "Salasanat eivät täsmää")
    .required("Salasana uudelleen on pakollinen"),
});

interface ParamTypes {
  token: string;
}
//Set PasswordScreen
const ConfirmRegisterForm = () => {
  const { token } = useParams<ParamTypes>();
  const [apiError, setApiError] = useState("");
  const classes = useAuthStyles();
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordAgain: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleRegister(values.password);
    },
  });
  let history = useHistory();

  /**
   * When navigating to page make a request to ask is token expired
   * Direct user to ask register request again
   */
  useEffect(() => {
    API.checkRegisterRequestToken(token).then((response) => {
      if (response.data.status === "failed") {
        dispatch(setConfirmDialogVisible(true));
      } else if (response.data.status === "success") {
        dispatch(setConfirmDialogVisible(false));
      }
    });
    //2. Direct user to register request page if it is
  }, []);

  const handleRegister = (password: string) => {
    dispatch(register({ token, password })).then((response: any) => {
      if (response.payload.status === "failed") {
        setApiError(response.payload.message);
      } else if (response.payload.status === "success") history.push("/login");
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <ConfirmDialog
        icon={<WarningIcon style={{ fontSize: 60 }} />}
        title="Rekisteröintipyyntönis on vanhentunut"
        message="Sinun täytyy pyytää rekisteröintiä uudelleen viimeistelläksesi rekisteröinnin."
        buttonUrl="/request-register"
        buttonText="Siirry rekisteröinnin pyyntöön"
      />
      <div className={classes.paper}>
        <Box>
          <div className={classes.loginLogo}>
            <img
              className={classes.logo}
              src={process.env.PUBLIC_URL + "/logo512x512.png"}
              alt="logo512"
            />
          </div>
        </Box>
        <Typography component="h3" variant="h5">
          Aseta salasana
        </Typography>
        <ApiError
          message={apiError}
          show={apiError.length > 0 ? true : false}
        />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Salasana"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            value={formik.values.passwordAgain}
            onChange={formik.handleChange}
            error={
              formik.touched.passwordAgain &&
              Boolean(formik.errors.passwordAgain)
            }
            helperText={
              formik.touched.passwordAgain && formik.errors.passwordAgain
            }
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="passwordAgain"
            label="Salasana uudelleen"
            type="password"
            id="passwordAgain"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Rekisteröidy
          </Button>
        </form>
      </div>
    </Container>
  );
};
export default ConfirmRegisterForm;
