import { makeStyles } from "@material-ui/core/styles";
export const useSearchStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    // marginTop: "-23px",
    //width: "35px",
    marginLeft: "-35px",
    //height: "65px",
    //margin: theme.spacing(1),
  },
  textfield: {
    color: "white",
    padding: 0,
    margin: 0,
    width: "180px",
    height: "35px",
  },
}));
