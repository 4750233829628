import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface DialogState {
  confirmDialogVisible: boolean;
  alertDialogVisible: boolean;
}

const initialState: DialogState = {
  confirmDialogVisible: false,
  alertDialogVisible: false,
};

export const confirmDialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    // Set the visibility of confirmpage
    setConfirmDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.confirmDialogVisible = action.payload;
    },
    setAlertDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.alertDialogVisible = action.payload;
    },
  },
});

export const { setConfirmDialogVisible, setAlertDialogVisible } =
  confirmDialogSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectVisibleStatus = (state: RootState) =>
  state.dialog.confirmDialogVisible;

export const selectAlertVisibleStatus = (state: RootState) =>
  state.dialog.alertDialogVisible;

export default confirmDialogSlice.reducer;
