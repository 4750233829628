import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ButtonGroup,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Link,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FileDownloadIcon from "@material-ui/icons/GetApp";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { selectPageNumber, setCurrentPageNumber } from "../slices/pageSlice";

import { createPDFfromResponse } from "../util/pdfblob";
/**
 * Renders a a provided PDF as interactive  dialog preview
 * This is and fullscreen popup version of the PDFFilePreview
 *
 */
interface PDFfilePreviewProps {
  filePath: any;
  onClose: any;
  dialogOpen: boolean;
}

const PDFfilePreviewDialog = (props: PDFfilePreviewProps) => {
  const { filePath, onClose, dialogOpen } = props;
  const [numPages, setNumPages] = useState(null);
  const selPage = useSelector(selectPageNumber);
  const dispatch = useDispatch();

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error: any) => {
    console.log("Document page Load Error", error.message);
  };

  const handleChangeNextPage = () => {
    const nextPage = selPage + 1;
    // @ts-ignore: Unreachable code error
    if (nextPage <= numPages) dispatch(setCurrentPageNumber(nextPage));
  };

  const handleChangePrevPage = () => {
    const nextPage = selPage - 1;
    // @ts-ignore: Unreachable code error
    if (nextPage > 0) dispatch(setCurrentPageNumber(nextPage));
  };

  const handleFileDownload = () => {
    const filename = "tosite";
    const link = document.createElement("a");
    link.href = filePath;
    link.download = `${filename}-${+new Date()}.pdf`;
    link.click();
  };

  let renderPageSize = 500;
  if (window.innerWidth < 1024) {
    renderPageSize = window.innerWidth - 100;
  } else if (window.innerWidth >= 1024) {
    renderPageSize = 900;
  }

  return (
    <Dialog
      open={dialogOpen}
      //onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      //PaperProps={{ classes: { root: classes.dialogPaper } }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Box display="flex" flexDirection="row-reverse">
          <Button onClick={onClose}>Sulje</Button>
        </Box>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Document
          file={filePath}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          error={<div>Esikatselun lataus epäonnistui</div>}
          onSourceError={(error: any) => console.log(error)}
        >
          <Page width={renderPageSize} pageNumber={selPage} />
        </Document>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <ButtonGroup disableElevation size="small" color="primary">
          <Button onClick={handleChangePrevPage}>
            <ArrowBackIcon />
          </Button>
          <Button>
            {selPage} / {numPages}
          </Button>
          <Button onClick={handleChangeNextPage}>
            <ArrowForwardIcon />
          </Button>
        </ButtonGroup>
        <Button
          color="secondary"
          size="small"
          variant="contained"
          startIcon={<FileDownloadIcon />}
          onClick={handleFileDownload}
        >
          Lataa PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PDFfilePreviewDialog;
