import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";
import { useHistory, useRouteMatch } from "react-router-dom";
import { format, parseISO } from "date-fns";
import {
  Container,
  List,
  ListSubheader,
  Typography,
  Box,
  TextField,
} from "@material-ui/core";
import ReceiptAddBottomNav from "./ReceiptAddBottomNav";

import { selectCompany } from "../../Customer/companySlice";
import {
  clearSelectedReceipt,
  getReceipts,
  ReceiptState,
  selectReceipts,
  selectSearchTerm,
  setSearchTerm,
  setSelectedReceipt,
} from "../../slices/receiptSlice";

import { useReceiptStyles } from "./receiptStyles";
import MonthSelect from "./MonthSelect/MonthSelect";
import Search from "./Search";
import { setNavBarActionStatus } from "../Navigation/navSlice";
import { setCurrentPageNumber } from "../../slices/pageSlice";

const ReceiptList = () => {
  const selectedCompany = useSelector(selectCompany);
  const searchTerm = useSelector(selectSearchTerm);
  const receipts = useSelector(selectReceipts);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  let { url } = useRouteMatch();
  let history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useReceiptStyles();

  useEffect(() => {
    dispatch(getReceipts());
    dispatch(clearSelectedReceipt());
    dispatch(setNavBarActionStatus("menu"));
    dispatch(setCurrentPageNumber(1));
    //dispatch(setSearchTerm(""));
    //dispatch(getReceipts());
    if (searchTerm.length > 0) {
      setIsSearchOpen(true);
    }
  }, [dispatch]);

  //Handle opening the search (shows the field, replaces month selection)
  const handleOpenSearch = () => {
    setIsSearchOpen(true);
  };

  const handleListItemClick = (receipt: ReceiptState) => {
    dispatch(setSelectedReceipt(receipt));
    history.push(`${url}/receipt/details/edit/${receipt.id}`);
  };

  //const cmp = (a: any, b: any) => (a > b) - (a < b);
  //TEST SORTING
  //Sort first by the receipts date, then with latest update date
  //(latest added if dates equals)

  /*   let sortedByReceiptDate = receipts.sort(
    (a, b) =>
      b.date.localeCompare(a.date) || b.updatedAt.localeCompare(a.updatedAt)
  ); */

  //console.table(sortedByReceiptDate);

  const receiptList = receipts.map((receipt: ReceiptState, index: any) => {
    return (
      <Box
        key={index}
        display="flex"
        flexDirection="column"
        className={classes.listItem}
        onClick={() => handleListItemClick(receipt)}
      >
        <Box display="flex" flexDirection="row">
          <Box flexGrow="2">
            <Typography className={classes.listItemTextPrimary}>
              {receipt.purchaseLocation}
            </Typography>
          </Box>
          <Box flexDirection="flex-end">
            <Typography className={classes.listItemDate}>
              {format(parseISO(receipt.date as string), "dd.MM.yyyy")}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography className={classes.listItemTextSecondary}>
            {receipt.additionalInfo}
          </Typography>
        </Box>
      </Box>
    );
  });

  //Count the receipts to be shown as a title in the listing
  const receiptsCount = receipts.length;

  return (
    <>
      <div className={classes.subToolBarVerticalContainer}>
        <div className={classes.subToolBar}>
          <Box
            display="flex"
            flexGrow="1"
            justifyContent="center"
            flexDirection="row"
          >
            {!isSearchOpen && <MonthSelect />}
            {isSearchOpen && (
              <TextField
                className={classes.searchContainer}
                label="Etsi tositetta"
                variant="outlined"
                margin="dense"
                size="small"
                value={searchTerm}
                onChange={(event: any) =>
                  dispatch(setSearchTerm(event.target.value))
                }
                InputProps={{
                  className: classes.searchInput,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </Box>
          <Box display="flex">
            <Search
              onClick={handleOpenSearch}
              open={isSearchOpen}
              onClose={() => {
                setIsSearchOpen(false);
                dispatch(setSearchTerm(""));
              }}
            />
          </Box>
        </div>
      </div>
      <Container className={classes.receiptList}>
        <List>
          <ListSubheader>
            {isSearchOpen
              ? `Hakutulokset: ${receiptsCount} kpl`
              : receiptsCount > 0
              ? `Tositteita ${receiptsCount} kpl`
              : `Ei tositteita`}
          </ListSubheader>
          {receiptList}
        </List>
      </Container>
      <ReceiptAddBottomNav />
    </>
  );
};
export default ReceiptList;
