import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router";

const HomeController = () => {
  const history = useHistory();
  useLayoutEffect(() => {
    const userRole = localStorage.getItem("user_role") || "";
    if (userRole === "customer") history.push("/customer");
    else if (userRole === "staff") history.push("/staff");
    else history.push("/login");
  }, []);

  return <div>Hi</div>;
};
export default HomeController;
