import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ReceiptState,
  selectSelectedReceipt,
  setSelectedReceipt,
} from "../../slices/receiptSlice";
import { DataGrid, GridColDef } from "@material-ui/data-grid";
import Chip from "@material-ui/core/Chip";
import ReceiptDetailsDialog from "./ReceiptDetailsDialog";
import { format, parseISO } from "date-fns";
import { setPrintReceipts } from "./printSlice";
import { GRID_FINNISH_LOCALE_TEXT } from "../../util/dataGridFiLocal";

//Renders receipts table
interface ReceiptsTableProps {
  receipts: ReceiptState[];
}
const ReceiptsTable = (props: ReceiptsTableProps) => {
  const [selectionModel, setSelectionModel] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const selectedReceipt = useSelector(selectSelectedReceipt);
  const dispatch = useDispatch();
  const { receipts } = props;

  useEffect(() => {
    dispatch(setPrintReceipts(selectedRows));
  }, [selectedRows]);

  const handleClose = () => {
    setDetailsDialogOpen(false);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "date",
      headerName: "Kuitin pvm",
      flex: 1,
      renderCell: (params) => (
        <div>{format(parseISO(params.value as string), "dd.MM.yyyy")}</div>
      ),
    },
    {
      field: "purchaseLocation",
      headerName: "Ostopaikka",
      width: 130,
      flex: 1,
    },
    { field: "additionalInfo", headerName: "Lisätiedot", width: 130, flex: 1 },
    {
      field: "user",
      headerName: "Lisääjä",
      flex: 1,
      //valueFormatter: (params) => params.row?.user?.name,
      valueGetter: (params) => {
        return params.row.user ? `${params.row?.user.name}` : "";
      },
    },
    {
      field: "processed",
      headerName: "Tila",
      width: 130,
      renderCell: (params) =>
        params.value === 1 ? (
          <Chip
            size="small"
            style={{ backgroundColor: "green" }}
            label="Käsitelty"
          />
        ) : (
          <Chip size="small" label="Ei käsitelty" />
        ),
    },
  ];

  return (
    <div style={{ height: "60vh", width: "100%" }}>
      <DataGrid
        localeText={GRID_FINNISH_LOCALE_TEXT}
        rows={receipts}
        columns={columns}
        pageSize={50}
        checkboxSelection
        disableSelectionOnClick={true}
        onRowClick={(param) => {
          dispatch(setSelectedReceipt(param.row));
          setDetailsDialogOpen(true);
        }}
        onSelectionModelChange={(newSelection) => {
          // @ts-ignore
          setSelectionModel(newSelection);
          const selectedIDs = new Set(newSelection);
          const selectedRowsData = receipts.filter((receipt: any) =>
            selectedIDs.has(receipt.id)
          );
          console.log(selectedRowsData);
          setSelectedRows(selectedRowsData);
        }}
        selectionModel={selectionModel}
      />
      <ReceiptDetailsDialog
        onClose={handleClose}
        open={detailsDialogOpen}
        receipt={selectedReceipt}
      />
    </div>
  );
};
export default ReceiptsTable;
