import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ListIcon from "@material-ui/icons/List";
import SecurityIcon from "@material-ui/icons/Security";
import ExitIcon from "@material-ui/icons/ExitToApp";

import { selectDrawerOpenStatus, toggleDrawerOpen } from "./navSlice";
import { logout, selectUser } from "../../Auth/userSlice";
import { useNavStyles } from "./navStyles";
import { useAppDispatch } from "../../app/store";

const MenuDrawer = () => {
  const drawerOpen = useSelector(selectDrawerOpenStatus);
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();
  let history = useHistory();
  const classes = useNavStyles({});

  //navigate to
  const handleItemClick = (route: string) => {
    dispatch(toggleDrawerOpen()); //toggle drawer close
    history.push(route);
  };

  return (
    <SwipeableDrawer
      classes={{ paper: classes.paper }}
      disableBackdropTransition
      anchor="left"
      open={drawerOpen}
      onOpen={() => dispatch(toggleDrawerOpen())}
      onClose={() => dispatch(toggleDrawerOpen())}
      // onBackdropClick={() => dispatch(toggleDrawerOpen())}
    >
      <div className={classes.drawerHeader}>
        <AccountCircleIcon />
        <Typography className={classes.drawerUserName} variant="subtitle2">
          {user.name}
        </Typography>
      </div>

      <List>
        <ListItem button></ListItem>
        <ListItem button onClick={() => handleItemClick("/customer")}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText>Tositteet</ListItemText>
        </ListItem>
        <ListItem button onClick={() => handleItemClick("/customer/privacy")}>
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText>Tietosuoja</ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText></ListItemText>
        </ListItem>
      </List>
      <div className={classes.drawerFooter}>
        <ListItem
          button
          onClick={() =>
            dispatch(logout()).then((response: any) => history.push("/login"))
          }
        >
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText>Kirjaudu ulos</ListItemText>
        </ListItem>
      </div>
    </SwipeableDrawer>
  );
};
export default MenuDrawer;
