import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { AppThunk, RootState } from "../app/store";
import API from "../util/api";

interface UserState {
  name: string;
  email: string;
  token: string;
}

const initialState: UserState = {
  name: "",
  email: "",
  token: "",
};

/**
 * Request interfaces
 */

interface LoginRequest {
  email: string;
  password: string;
}

interface RegisterRequest {
  token: string;
  password: string;
}

/**
 * Async actions
 */
export const login = createAsyncThunk("/login", async (props: LoginRequest) => {
  const { email, password } = props;
  const response = await API.login(email, password);
  return response.data;
});

export const logout = createAsyncThunk("/logout", async () => {
  return API.logout().then((response: any) => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_role");
    return response;
  });
});

export const register = createAsyncThunk(
  "/register",
  async (props: RegisterRequest) => {
    const { token, password } = props;
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/register`,
      {
        token,
        password,
      }
    );
    return response.data;
  }
);

//Get user info
export const getAuthUser = (): AppThunk => (dispatch) => {
  return API.getAuthUser().then((response: any) => {
    dispatch(setUserEmail(response.data.email));
    dispatch(setUserName(response.data.name));
    const token = localStorage.getItem("access_token") || "";
    dispatch(setUserToken(token));
  });
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }: any) => {
      if (payload.access_token && payload.user) {
        state.email = payload.user.email;
        state.name = payload.user.name;
        state.token = payload.access_token;
        localStorage.setItem("access_token", payload.access_token);
        localStorage.setItem("user_role", payload.user.role);
        return state;
      }
    });
  },
});

export const { setUserEmail, setUserName, setUserToken } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const selectUserToken = (state: RootState) => state.user.token;

export default userSlice.reducer;
