import React from "react";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";

import { useSearchStyles } from "./searchStyles";

interface SearchProps {
  onClick: any;
  //onChange: any; //real time search
  open: boolean; //indicate state of search field
  onClose: any; //for closeing search
}

const Search = (props: SearchProps) => {
  const classes = useSearchStyles();
  const { onClick, open, onClose } = props;

  return (
    <div className={classes.container}>
      {!open && (
        <IconButton
          style={{ color: "white", padding: 5, margin: 0 }}
          aria-label="open search"
          component="span"
          onClick={onClick}
        >
          <SearchIcon />
        </IconButton>
      )}
      {open && (
        <IconButton
          style={{ color: "white", padding: 5, margin: 0 }}
          aria-label="search receipt"
          component="span"
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
      )}
    </div>
  );
};
export default Search;
