import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Box,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowBack as BackIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import MenuDrawer from "./MenuDrawer";
import { useNavStyles } from "./navStyles";
import {
  CompanyState,
  selectCompany,
  selectUserCompanies,
  changeUserSelectedCompany,
} from "../companySlice";
import { selectNavBarActionStatus, toggleDrawerOpen } from "./navSlice";
import { useLocation } from "react-router-dom";
import { changeSelectedReceiptCompanyId } from "../../slices/receiptSlice";

import { setAlertDialogVisible } from "../../Dialogs/confirmDialogSlice";

const NavBar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [navBarTitle, setNavBarTitle] = useState<string>("MSTosite");
  const [showLogo, setShowLogo] = useState(false);
  const selectedCompany = useSelector(selectCompany);
  const userCompanies = useSelector(selectUserCompanies);
  const navBarActionStatus = useSelector(selectNavBarActionStatus);
  const dispatch = useDispatch();
  const classes = useNavStyles({});

  let history = useHistory();
  let location = useLocation();

  /**
   * Change navBarTitle based on the path when url location changes. Default value is app title.
   */
  useEffect(() => {
    let pathValuesArray = location.pathname.split("/");
    if (
      (pathValuesArray.includes("receipt") &&
        pathValuesArray.includes("details") &&
        !pathValuesArray.includes("edit")) ||
      (pathValuesArray.includes("receipt") &&
        pathValuesArray.includes("file") &&
        !pathValuesArray.includes("edit"))
    ) {
      setNavBarTitle("Lisää tosite");
    } else {
      //Set show logo true
      setShowLogo(true);
      //setNavBarTitle("MSTosite");
    }
  }, [location]);

  //useEffect(() => {}, [navBarActionStatus]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCompanyChange = (id: any) => {
    dispatch(changeUserSelectedCompany(id));
    dispatch(changeSelectedReceiptCompanyId(id));
    //Dispatch action to filter receipts in redux store?
    handleClose();
  };

  //Handle close case where we want to confirm closing first
  const handleCloseAndConfirm = () => {
    dispatch(setAlertDialogVisible(true));
    //history.push("/customer");
  };

  // Handle back navigation
  // if in edit form, we want to just navigate always back to /customer
  // otherwise default is goBack()
  const handleBackNavigation = () => {
    let pathValuesArray = location.pathname.split("/");
    if (pathValuesArray.includes("edit")) {
      history.push("/customer");
    } else {
      history.goBack();
    }
  };

  interface NavBarActionIconButtonProps {
    status: string;
  }
  const NavBarActionIconButton = (props: NavBarActionIconButtonProps) => {
    const { status } = props;

    if (status === "menu") {
      return (
        <IconButton
          onClick={() => dispatch(toggleDrawerOpen())}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
      );
    } else if (status === "back") {
      return (
        <IconButton
          onClick={handleBackNavigation}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <BackIcon />
        </IconButton>
      );
    } else if (status === "close") {
      return (
        <IconButton
          onClick={() => history.push("/customer")}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <CloseIcon />
        </IconButton>
      );
    } else if (status === "closeAndConfirm") {
      return (
        <IconButton
          onClick={handleCloseAndConfirm}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <CloseIcon />
        </IconButton>
      );
    }
    return (
      <IconButton
        onClick={() => dispatch(toggleDrawerOpen())}
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        <MenuIcon />
      </IconButton>
    );
  };

  const userCompaniesList = userCompanies.map(
    (company: CompanyState, index: number) => {
      return (
        <MenuItem
          onClick={() => handleCompanyChange(company.id)}
          key={`m_item_${index}`}
        >
          {company.businessName}
        </MenuItem>
      );
    }
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <NavBarActionIconButton status={navBarActionStatus} />
          {showLogo ? (
            <img
              alt="MSTosite"
              className={classes.logo}
              src={process.env.PUBLIC_URL + "/logo512x72.png"}
            />
          ) : (
            <Typography variant="h6" className={classes.title}>
              {navBarTitle}
            </Typography>
          )}
          <div className={classes.dropDownMenuContainer}>
            <Box
              onClick={handleClick}
              style={{
                flexGrow: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Typography noWrap className={classes.dropDownMenu}>
                {selectedCompany.businessName || ""}
              </Typography>
              <ArrowDropDownIcon />
            </Box>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {userCompaniesList}
          </Menu>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
      <MenuDrawer />
    </div>
  );
};
export default NavBar;
