import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../app/store";
import { Box, Typography, Button, Dialog } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDialogStyles } from "./dialogStyles";
import {
  selectVisibleStatus,
  setConfirmDialogVisible,
} from "./confirmDialogSlice";

interface ConfirmDialogProps {
  icon: React.ReactElement;
  title: string;
  message: string;
  buttonUrl: string;
  buttonText: string;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const isVisible = useSelector(selectVisibleStatus);
  const { icon, title, message, buttonUrl, buttonText } = props;
  const classes = useDialogStyles();
  let history = useHistory();
  const dispatch = useAppDispatch();
  return (
    <Dialog fullScreen open={isVisible}>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <div className={classes.paper}>
          {icon}
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1">{message}</Typography>
          <Button
            className={classes.button}
            onClick={() => {
              dispatch(setConfirmDialogVisible(false));
              history.push(buttonUrl);
            }}
            variant="contained"
            color="primary"
          >
            {buttonText}
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};
export default ConfirmDialog;
