import React, { useState } from "react";
import { useDispatch } from "react-redux";
import API from "../util/api";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
} from "@material-ui/core";
import { useAuthStyles } from "../Auth/authStyles";
import ApiError from "../Error/ApiError";
import { useFormik } from "formik";
import * as yup from "yup";
import { setConfirmDialogVisible } from "../Dialogs/confirmDialogSlice";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import { Email as EmailIcon } from "@material-ui/icons";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .strict(false)
    .email("Syötä oikea sähköpostiosoite")
    .required("Sähköpostiosoite on pakollinen"),
  businessName: yup
    .string()
    .trim()
    //.strict(true)
    .required("Yrityksen nimi on pakollinen"),
  businessId: yup
    .string()
    .trim()
    .required()
    .max(9, "Y-tunnus ei ole oikein")
    .matches(/^[0-9]{6,7}-[0-9]{1}$/, "Y-tunnus ei ole oikein"), //KORJAA TÄMÄ, EI TOIMI ?!?!? ^[0-9]{6,7}-[0-9]{1}$ tää on hyvä PHP:Lle ei toimi javascriptissä
  personName: yup.string().trim().required("Nimi on pakollinen"),
  phoneNumber: yup
    .number()
    .min(7, "Puhelinnumero on liian lyhyt")
    .typeError("Puhelinnumerossa ei voi olla kirjaimia")
    .integer()
    .positive()
    .required("Puhelinnumero on pakollinen"),
});

interface ParamTypes {
  email: string;
}
//Login screen
const CorporateRequestForm = () => {
  const { email } = useParams<ParamTypes>();
  const [apiError, setApiError] = useState("");
  const classes = useAuthStyles();
  const formik = useFormik({
    initialValues: {
      email: email,
      businessName: "",
      businessId: "",
      personName: "",
      phoneNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Manual trim of values because trimmed value from up does not get passed
      // to formik's onSubmit for some reason....
      let trimmedValues = {
        email: values.email.trim(),
        businessName: values.businessName.trim(),
        businessId: values.businessId.trim(),
        personName: values.personName.trim(),
        phoneNumber: values.phoneNumber.trim(),
      };
      handleCorporateRequest(trimmedValues);
    },
  });

  const dispatch = useDispatch();

  const handleCorporateRequest = (values: any) => {
    console.log(values);
    API.sendBusinessInfoForm(values).then((response) => {
      if (response.data.status === "success") {
        dispatch(setConfirmDialogVisible(true));
      } else if (response.data.status === "failed") {
        setApiError(response.data.message);
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <ConfirmDialog
        icon={<EmailIcon style={{ fontSize: 60 }} />}
        title="Tunnustenpyyntö lähetetty"
        message="Tunnusten luontipyyntö on lähetetty. Käsittelemme sen mahdollisimman
        pian."
        buttonUrl="/login"
        buttonText="Palaa kirjautumiseen"
      />
      <div className={classes.paper}>
        <Box>
          <div className={classes.loginLogo}>
            <img
              className={classes.logo}
              src={process.env.PUBLIC_URL + "/logo512x512.png"}
              alt="logo512"
            />
          </div>
        </Box>
        <Typography variant="subtitle2">
          Syötä alla olevalle lomakkeelle yrityksen tiedot, niin olemme sinuun
          yhteydessä rekisteröinnistä.
        </Typography>
        <ApiError
          message={apiError}
          show={apiError.length > 0 ? true : false}
        />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Sähköposti"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            value={formik.values.businessName}
            onChange={formik.handleChange}
            error={
              formik.touched.businessName && Boolean(formik.errors.businessName)
            }
            helperText={
              formik.touched.businessName && formik.errors.businessName
            }
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="businessName"
            label="Yrityksen nimi"
            name="businessName"
            autoComplete="Yrityksen nimi"
            autoFocus
          />
          <TextField
            value={formik.values.businessId}
            onChange={formik.handleChange}
            error={
              formik.touched.businessId && Boolean(formik.errors.businessId)
            }
            helperText={formik.touched.businessId && formik.errors.businessId}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="businessId"
            label="Y-tunnus"
            name="businessId"
            autoComplete="Yrityksen nimi"
            autoFocus
          />
          <TextField
            value={formik.values.personName}
            onChange={formik.handleChange}
            error={
              formik.touched.personName && Boolean(formik.errors.personName)
            }
            helperText={formik.touched.personName && formik.errors.personName}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="personName"
            label="Nimi"
            name="personName"
            autoComplete="Yhteyshenkilön nimi"
            autoFocus
          />
          <TextField
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Puhelinnumero"
            name="phoneNumber"
            autoComplete="Puhelinnumero"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Lähetä tiedot
          </Button>
        </form>
      </div>
    </Container>
  );
};
export default CorporateRequestForm;
