import React from "react";
import { Paper, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorOutlined as ErrorIcon } from "@material-ui/icons";

export const useApiErrorStyles = makeStyles((theme) => ({
  alert: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "rgb(253, 236, 234)",
    minWidth: "100%",
    minHeight: "35px",
    color: "#b30000",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorText: {
    margin: theme.spacing(1),
  },
  errorIcon: {
    margin: theme.spacing(1),
  },
}));

interface ApiErrorProps {
  message: string;
  show: boolean;
}
const ApiError = (props: ApiErrorProps) => {
  const { message, show } = props;
  const classes = useApiErrorStyles();

  return (
    <div>
      {show && (
        <Paper className={classes.alert} elevation={1}>
          <ErrorIcon className={classes.errorIcon} />
          <Box className={classes.errorText}>{message}</Box>
        </Paper>
      )}
    </div>
  );
};
export default ApiError;
