//import { GridLocaleText } from "../models/api/gridLocaleTextApi";

export const GRID_FINNISH_LOCALE_TEXT: any = {
  // Root
  noRowsLabel: "Ei tositteita",
  noResultsOverlayLabel: "Ei tuloksia.",
  errorOverlayDefaultLabel: "Tapahtui virhe.",

  // Density selector toolbar button text
  toolbarDensity: "Density",
  toolbarDensityLabel: "Density",
  toolbarDensityCompact: "Compact",
  toolbarDensityStandard: "Standard",
  toolbarDensityComfortable: "Comfortable",

  // Columns selector toolbar button text
  toolbarColumns: "Columns",
  toolbarColumnsLabel: "Select columns",

  // Filters toolbar button text
  toolbarFilters: "Filters",
  toolbarFiltersLabel: "Show filters",
  toolbarFiltersTooltipHide: "Hide filters",
  toolbarFiltersTooltipShow: "Show filters",
  toolbarFiltersTooltipActive: (count: number) =>
    count !== 1 ? `${count} active filters` : `${count} active filter`,

  // Export selector toolbar button text
  toolbarExport: "Export",
  toolbarExportLabel: "Export",
  toolbarExportCSV: "Download as CSV",

  // Columns panel text
  columnsPanelTextFieldLabel: "Etsi sarake",
  columnsPanelTextFieldPlaceholder: "Column title",
  columnsPanelDragIconLabel: "Reorder column",
  columnsPanelShowAllButton: "Näytä kaikki",
  columnsPanelHideAllButton: "Piilota kaikki",

  // Filter panel text
  filterPanelAddFilter: "Lisää suodatin",
  filterPanelDeleteIconLabel: "Poista",
  filterPanelOperators: "Operaattorit",
  filterPanelOperatorAnd: "Ja",
  filterPanelOperatorOr: "Tai",
  filterPanelColumns: "Sarakkeet",
  filterPanelInputLabel: "Arvo",
  filterPanelInputPlaceholder: "Suodatusarvo",

  // Filter operators text
  filterOperatorContains: "sisältää",
  filterOperatorEquals: "yhtä kuin",
  filterOperatorStartsWith: "alkaa",
  filterOperatorEndsWith: "loppuu",
  filterOperatorIs: "on",
  filterOperatorNot: "ei ole",
  filterOperatorAfter: "on jälkeen",
  filterOperatorOnOrAfter: "is on or after",
  filterOperatorBefore: "is before",
  filterOperatorOnOrBefore: "is on or before",

  // Filter values text
  filterValueAny: "mitä tahansa",
  filterValueTrue: "kyllä",
  filterValueFalse: "ei",

  // Column menu text
  columnMenuLabel: "Valikko",
  columnMenuShowColumns: "Näytä sarakkeet",
  columnMenuFilter: "Suodatin",
  columnMenuHideColumn: "Piilota",
  columnMenuUnsort: "Poista järjestys",
  columnMenuSortAsc: "Järjestä laskevasti",
  columnMenuSortDesc: "Järjestä nousevasti",

  // Column header text
  columnHeaderFiltersTooltipActive: (count: number) =>
    count !== 1
      ? `${count} aktiiviset suodattimet`
      : `${count} aktiivinen suodatin`,
  columnHeaderFiltersLabel: "Näytä suodattimet",
  columnHeaderSortIconLabel: "Järjestä",

  // Rows selected footer text
  footerRowSelected: (count: number) =>
    count !== 1
      ? `${count.toLocaleString()} rivejä valittuna`
      : `${count.toLocaleString()} rivi valittu`,

  // Total rows footer text
  footerTotalRows: "Rivit yhteensä:",

  // Checkbox selection text
  checkboxSelectionHeaderName: "Checkbox selection",

  // Boolean cell text
  booleanCellTrueLabel: "true",
  booleanCellFalseLabel: "false",

  //Used core components translation keys
  MuiTablePagination: {
    labelRowsPerPage: "Rivejä yhteensä",
  },
};
