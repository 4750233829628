import { makeStyles } from "@material-ui/core/styles";
export const useReceiptStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  receiptContainer: {
    flexGrow: 1,
    paddingBottom: "65px",
  },
  buttonsList: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  receiptList: {
    paddingBottom: "45px",
    height: "100vh auto",
    backgroundColor: "#f6f6f6",
  },
  listItem: {
    boxSizing: "border-box",
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 0,
    borderColor: "black",
    backgroundColor: "#fff",
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1.5),
  },
  listItemText: {
    //maxWidth: "220px",
  },
  listItemTextPrimary: {
    fontFamily: "Helvetica",
    fontWeight: 501,
  },
  listItemDate: {
    fontFamily: "Helvetica",
    fontSize: "0.85rem",
    fontWeight: 501,
    paddingTop: "2px",
  },
  listItemTextSecondary: {
    fontFamily: "Helvetica",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "50px",
    color: "#616664",
    fontSize: "0.9rem",
  },
  listItemSecondary: {
    paddingTop: "20px",
    height: "100%",
  },
  inline: {
    display: "inline",
    wordBreak: "break-word",
  },
  receiptImgContainer: {
    maxWidth: window.innerWidth - 130,
    margin: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  receiptImg: {
    width: "100px",
    height: "auto",
  },
  formControl: {
    margin: theme.spacing(3, 0, 1, 0),
  },
  bottomNavButton: {
    color: "white",
    textTransform: "uppercase",
  },
  fileUploadContainer: {
    boxSizing: "border-box",
    height: "100%",
    padding: theme.spacing(3, 0),
  },
  fileUploadButton: {
    width: "100%",
    margin: theme.spacing(3, 0, 1, 0),
  },
  subToolBarVerticalContainer: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    height: "55px",
    justifyContent: "center",
  },
  subToolBar: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    //height: "55px",
    paddingRight: "10px",
    //justifyContent: "center",
  },
  cameraPreviewsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: 13,
    width: "100%",
  },
  cameraPreviewItem: {
    margin: 3,
  },
  cameraPreviewImg: {
    width: 50,
    borderRadius: "8%",
  },
  searchContainer: {
    margin: 0,
    padding: 0,
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#157D3A",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "black",
    },
  },
  searchInput: {
    ///height: "35px",
    borderColor: "black",
    backgroundColor: "#20984A",
    //fontSize: "12px",
  },
}));
