import React from "react";
import { Backdrop, Box, CircularProgress, Typography } from "@material-ui/core";
import { useNavStyles } from "./Customer/Navigation/navStyles";

interface LoadingScreenProps {
  loading: boolean;
  loadingText: string;
}
const LoadingScreen = (props: LoadingScreenProps) => {
  const { loading, loadingText } = props;
  const classesNav = useNavStyles({ loading });
  return (
    <Backdrop open={loading as boolean} className={classesNav.backDrop}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress color="inherit" />
        <Box mt={3}>
          <Typography variant="subtitle2">{loadingText}</Typography>
        </Box>
      </Box>
    </Backdrop>
  );
};
export default LoadingScreen;
