import React from "react";
import { useReceiptStyles } from "./receiptStyles";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { useHistory } from "react-router";

//Receipt details image preview of camera taken shots
interface CameraImagesPreviewProps {
  images: any;
}
const CameraImagesPreview = (props: CameraImagesPreviewProps) => {
  const { images } = props;
  const classes = useReceiptStyles();
  let history = useHistory();

  const imagesList = images.map((image: any) => {
    return (
      <div className={classes.cameraPreviewItem} key={image.id}>
        <img
          className={classes.cameraPreviewImg}
          src={image.img}
          alt="prevImg"
        />
      </div>
    );
  });

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div className={classes.cameraPreviewsContainer}>{imagesList}</div>
      <PhotoLibraryIcon
        onClick={() => history.push("/customer/camera/gallery")}
        fontSize="large"
      />
    </div>
  );
};
export default CameraImagesPreview;
