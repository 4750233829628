import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  Link,
  Box,
} from "@material-ui/core";

import { useAuthStyles } from "./authStyles";
import API from "../util/api";
import ApiError from "../Error/ApiError";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Salasanan on oltava vähintään 8 merkkiä pitkä")
    .required("Salasana on pakollinen"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Salasanat eivät täsmää")
    .required("Salasana uudelleen on pakollinen"),
});

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.mst-yhtiot.fi/">
        MSTosite
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

interface ParamTypes {
  token: string;
  email: string;
}

//Set new password
const PasswordResetForm = () => {
  const { token, email } = useParams<ParamTypes>();
  const classes = useAuthStyles();
  const [apiError, setApiError] = useState("");
  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      handlePasswordReset(values.password, values.password_confirmation);
    },
  });
  let history = useHistory();

  const handlePasswordReset = (
    password: string,
    password_confirmation: string
  ) => {
    API.passwordReset({ token, email, password, password_confirmation }).then(
      (response: any) => {
        console.log("Okay, response", response);
        if (response.data.status === "failed") {
          setApiError(response.data.message);
        } else {
          history.push("/");
        }
      }
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Box>
          <div className={classes.loginLogo}>
            <img
              className={classes.logo}
              src={process.env.PUBLIC_URL + "/logo512x512.png"}
              alt="logo512"
            />
          </div>
        </Box>
        <Typography component="h3" variant="h5">
          Aseta uusi salasana
        </Typography>
        <ApiError
          message={apiError}
          show={apiError.length > 0 ? true : false}
        />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Salasana"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <TextField
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            error={
              formik.touched.password_confirmation &&
              Boolean(formik.errors.password_confirmation)
            }
            helperText={
              formik.touched.password_confirmation &&
              formik.errors.password_confirmation
            }
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password_confirmation"
            label="Salasana uudelleen"
            type="password"
            id="password_confirmation"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Lähetä
          </Button>
        </form>
        <Link href="/login" variant="body2">
          Takaisin kirjautumiseen
        </Link>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
export default PasswordResetForm;
