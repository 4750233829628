import { useAuthStyles } from "../Auth/authStyles";

/**
 * App store embed badges
 *
 * Shown only
 */
export const GooglePlayStoreLink = () => {
  const classes = useAuthStyles();
  return (
    <a href="https://play.google.com/store/apps/details?id=fi.mstosite.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
      <img
        className={classes.googlePlayBadge}
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/fi_badge_web_generic.png"
      />
    </a>
  );
};

export const AppleAppStoreLink = () => {
  return (
    <a
      href="https://apps.apple.com/fi/app/mstosite/id1608021428"
      style={{
        display: "inline-block",
        overflow: "hidden",
        borderRadius: "13px",
        width: "6.5rem",
        //height: "83px",
        margin: "5px 0px",
      }}
    >
      <img
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1644969600&h=01656879720e3b0e970e712f20b9eceb"
        alt="Download on the App Store"
        style={{
          borderRadius: "13px",
          width: "6.5rem",
          //height: "83px",
        }}
      />
    </a>
  );
};
