import { makeStyles } from "@material-ui/core/styles";
export const useAuthStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  loginLogo: {
    maxWidth: window.innerWidth - 150,
  },
  logo: {
    width: "100%",
  },
  googlePlayBadge: {
    width: "8.5rem",
    height: "auto",
  },
}));
