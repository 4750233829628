import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import queryString from "query-string";
import ReceiptsTable from "./ReceiptsTable";
import {
  getReceiptsByCompanyId,
  getReceiptsByIds,
  selectReceiptsForCompany,
  selectStatusFilter,
  setReceiptProcessedStatus,
  setSearchTerm,
  setStatusFilter,
} from "../../slices/receiptSlice";
import { selectCompany, setSelectedCompany } from "../../Customer/companySlice";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import print from "print-js";
import API from "../../util/api";
import { selectPrintReceipts } from "./printSlice";
import FloatError from "../../Error/FloatError";
import { selectUserToken } from "../../Auth/userSlice";

//List of receipts for the company id provided in as url param
const ReceiptList = () => {
  let { companyId } = useParams<any>();
  const userToken = useSelector(selectUserToken);
  const statusFilter = useSelector(selectStatusFilter);
  const dispatch = useDispatch();
  const receipts = useSelector(selectReceiptsForCompany);
  const company = useSelector(selectCompany);
  const printReceipts = useSelector(selectPrintReceipts);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showApiError, setShowApiError] = useState(false);
  let history = useHistory();

  useEffect(() => {
    //Fetch company receipts if company id param is present
    if (companyId) {
      dispatch(getReceiptsByCompanyId(companyId));
      //..else if we navigated from printlog, fetch
      // receipts by their id?
    } else {
      dispatch(setSelectedCompany({}));
      const query = queryString.parse(window.location.search, {
        arrayFormat: "comma",
      });

      const ids: any = !!query.ids
        ? Array.isArray(query.ids)
          ? query.ids
          : [query.ids]
        : [];
      if (ids) dispatch(getReceiptsByIds(ids));
    }
  }, [companyId]);

  const handleSelectedPrint = () => {
    if (printReceipts.length > 0) {
      //Make a request to set processed status true
      const receiptsIds: string[] = printReceipts.map((item) => item.id!);
      setShowApiError(false);
      setLoading(true);
      API.getPrintablePDF(printReceipts).then((response: any) => {
        // If we successfully form the printable pdf from selected receipts:
        // - set print preview & set receipts statuses to "käsitelty"
        if (response.status === 200) {
          /**
           * Get the merged PDF file to be printed with print.js
           */
          //Show print dialog
          print({
            printable: `${process.env.REACT_APP_API_URL}/receipt/print/file/${response.data.data}?token=${userToken}`,
            type: "pdf",
            onLoadingEnd: () => setLoading(false),
          });

          API.processPrintables(receiptsIds).then((response: any) => {
            if (response.data.status === "success") {
              response.data.data.forEach((item: any) => {
                dispatch(setReceiptProcessedStatus(item.id));
              });
            } else {
              //Shoe receipts käsitely could not be done error!
              setShowApiError(true);
              setApiError("Tositteita ei saatu käsiteltyä");
              setLoading(false);
            }
            //setLoading(false);
          });
        } else {
          //Show cannot form printable pdf error!
          setShowApiError(true);
          setLoading(false);
          setApiError("Tulostetta ei voitu muodostaa");
        }
      });
    } else {
      //console.log("Valitse ensin tositteet");
    }
  };

  const setReceiptStatusFilter = (status: string) => {
    dispatch(setStatusFilter(status));
  };

  return (
    <Container maxWidth={false}>
      <FloatError
        message={apiError}
        show={showApiError}
        handleClose={() => setShowApiError(false)}
      />
      <Box
        display="flex"
        flexDirection="column"
        style={{ marginBottom: "10px" }}
      >
        <Box display="flex" flexDirection="row" m={2}>
          <Box flexGrow="0" display="flex">
            <Box>
              <Typography variant="h4">
                {company.businessName ? company.businessName : "Tositteet "}
              </Typography>
            </Box>
          </Box>
          <Box
            ml={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => history.push("/staff")}
            >
              Valitse yritys
            </Button>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center" m={2}>
          <Box mr={3}>
            <Button
              style={
                statusFilter === "" ? { color: "green" } : { color: "black" }
              }
              size="small"
              onClick={() => setReceiptStatusFilter("")}
            >
              Kaikki
            </Button>{" "}
            |
            <Button
              size="small"
              style={
                statusFilter === "käsitelty"
                  ? { color: "green" }
                  : { color: "black" }
              }
              onClick={() => setReceiptStatusFilter("käsitelty")}
            >
              Käsitelty
            </Button>{" "}
            |
            <Button
              size="small"
              style={
                statusFilter === "ei käsitelty"
                  ? { color: "green" }
                  : { color: "black" }
              }
              onClick={() => setReceiptStatusFilter("ei käsitelty")}
            >
              Ei Käsitelty
            </Button>
          </Box>
          <Box mr={3}>
            <TextField
              label="Etsi tositetta"
              variant="outlined"
              margin="dense"
              size="small"
              onChange={(event: any) =>
                dispatch(setSearchTerm(event.target.value))
              }
            />
          </Box>
          <Box>
            <Box style={{ position: "relative", margin: 3 }}>
              <Button
                disabled={loading}
                startIcon={<PrintIcon />}
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => handleSelectedPrint()}
              >
                Tulosta
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        <ReceiptsTable receipts={receipts} />
      </Box>
    </Container>
  );
};
export default ReceiptList;
