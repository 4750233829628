import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  Link,
  Box,
} from "@material-ui/core";
import { useAuthStyles } from "./authStyles";
import API from "../util/api";
import { useFormik } from "formik";
import * as yup from "yup";
import { setConfirmDialogVisible } from "../Dialogs/confirmDialogSlice";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import { Email as EmailIcon } from "@material-ui/icons";
import ApiError from "../Error/ApiError";

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .strict(false)
    .email("Syötä oikea sähköpostiosoite")
    .required("Sähköpostiosoite on pakollinen"),
});

const RequestRegisterForm = () => {
  const [apiError, setApiError] = useState("");
  const classes = useAuthStyles();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleRequestRegister(values.email.trim());
    },
  });
  let history = useHistory();
  const dispatch = useDispatch();

  const handleRequestRegister = (email: string) => {
    API.requestRegister(email).then((response: any) => {
      if (response.data.status === "success") {
        dispatch(setConfirmDialogVisible(true));
      } else if (response.data.error === "user-not-found") {
        history.push(`/corporate-request-form/${email}`);
      } else if (response.data.status === "failed") {
        setApiError(response.data.message);
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <ConfirmDialog
        icon={<EmailIcon style={{ fontSize: 60 }} />}
        title="Rekisteröinnin vahvistus lähetetty"
        message=" Olemme lähettäneet sinulle rekisteröintilinkin sähköpostiisi. Seuraa
    sähköpostin ohjeita viimeistelläksesi rekisteröitymisen."
        buttonUrl="/login"
        buttonText="Palaa kirjautumiseen"
      />
      <div className={classes.paper}>
        <Box>
          <div className={classes.loginLogo}>
            <img
              className={classes.logo}
              src={process.env.PUBLIC_URL + "/logo512x512.png"}
              alt="logo512x210"
            />
          </div>
        </Box>
        <Box pb={2}>
          <Typography component="h1" variant="h5">
            Pyydä rekisteröintiä
          </Typography>
        </Box>
        <Typography
          component="p"
          variant="subtitle1"
          style={{ flexGrow: 1, textAlign: "center" }}
        >
          Syötä sähköpostiosoitteesi ja lähetämme sinulle rekisteröintilinkin.
        </Typography>
        <ApiError
          message={apiError}
          show={apiError.length > 0 ? true : false}
        />
        <form onSubmit={formik.handleSubmit}>
          <TextField
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Sähköposti"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Pyydä rekisteröintiä
          </Button>
        </form>
        <Link href="/login" variant="body2">
          Takaisin kirjautumiseen
        </Link>
      </div>
    </Container>
  );
};
export default RequestRegisterForm;
