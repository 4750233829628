import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  selectNotifcationMessage,
  selectNotificationOpenStatus,
  setNotificationOpen,
} from "./notificationSlice";

/**
 * Show message of user actions on the UI
 * e.g success of update, delete actions on receipts etc.
 *
 *  */
const Notification = () => {
  const open = useSelector(selectNotificationOpenStatus);
  const message = useSelector(selectNotifcationMessage);
  const dispatch = useDispatch();

  const handleClose = (event: any, reason: any) => {
    dispatch(setNotificationOpen({ open: false, message: "" }));
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() =>
          dispatch(setNotificationOpen({ open: false, message: "" }))
        }
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
};
export default Notification;
