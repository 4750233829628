import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import "./App.css";

//Authentication routes
import Login from "./Auth/Login";
import RequestRegisterForm from "./Auth/RequestRegisterForm";
import ConfirmRegisterForm from "./Auth/ConfirmRegisterForm";
import HomeController from "./HomeController";
import CorporateRequestForm from "./Forms/CorporateRequestForm";
import PasswordResetRequestForm from "./Auth/PasswordResetRequestForm";
import PasswordResetForm from "./Auth/PasswordResetForm";

//Customer routes
import { App as CustomerApp } from "./Customer/App";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

//Staff routes
import { App as StaffApp } from "./Staff/App";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fi } from "date-fns/locale";

//Global MST-yhtiot theme
const MSTheme = createTheme({
  palette: {
    primary: {
      main: "#21A750",
    },
    secondary: {
      main: "#E33E7F",
    },
    background: {
      default: "#f6f6f6",
    },
  },
  props: {
    MuiTablePagination: {
      labelRowsPerPage: "Rivejä yhteensä",
      labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count}`,
    },
  },
  /*   overrides: {
    MuiOutlinedInput: {
      input: {
        background: "red",
      },
    },
  }, */
});

function App() {
  return (
    <MuiThemeProvider theme={MSTheme}>
      <CssBaseline />
      <MuiPickersUtilsProvider locale={fi} utils={DateFnsUtils}>
        <Switch>
          <PrivateRoute exact path="/" roles={["customer", "staff"]}>
            <HomeController />
          </PrivateRoute>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/request-register">
            <RequestRegisterForm />
          </Route>
          <Route path="/register/:token">
            <ConfirmRegisterForm />
          </Route>
          <Route path="/password-request-reset-form">
            <PasswordResetRequestForm />
          </Route>
          <Route path="/password-reset-form/:token/:email">
            <PasswordResetForm />
          </Route>
          <PrivateRoute path="/customer" roles={["customer"]}>
            <CustomerApp />
          </PrivateRoute>
          <PrivateRoute path="/staff" roles={["staff"]}>
            <StaffApp />
          </PrivateRoute>
          <Route path="/corporate-request-form/:email">
            <CorporateRequestForm />
          </Route>
        </Switch>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default App;
